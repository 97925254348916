import { graphql, useFragment } from 'react-relay';

import {
  AlimTalkPreview_alimTalkPreview$data,
  AlimTalkPreview_alimTalkPreview$key,
} from '../../../relay/__generated__/AlimTalkPreview_alimTalkPreview.graphql';
import {
  AlimTalkPreview_alimTalkTemplate$data,
  AlimTalkPreview_alimTalkTemplate$key,
} from '../../../relay/__generated__/AlimTalkPreview_alimTalkTemplate.graphql';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import Text from '../../core/Text';
import type { ViewProps } from '../../core/View';
import View from '../../core/View';
import KakaoBizMessagePreview, {
  KakaoBizBasicMessageType,
  KakaoBizCommonMessageProps,
  KakaoBizEmphasisMessageType,
  KakaoBizImageMessageType,
  KakaoBizItemListMessageType,
  KakaoBizMessageProps,
  KakaoBizMessageTypes,
} from '../../kakaoBizMessage/KakaoBizMessagePreview';
import { Button } from '../../kakaoBizMessage/KakaoBizMessagePreview/messages/BaseMessage';

const alimTalkPreviewFragment = graphql`
  fragment AlimTalkPreview_alimTalkPreview on AlimTalkPreview {
    targetCount
    messages {
      to
      title
      headerContent
      itemHighlight {
        title
        description
      }
      item {
        list {
          title
          description
        }
        summary {
          title
          description
        }
      }
      content
      buttons {
        type
        name
        linkMobile
        linkPc
        schemeIos
        schemeAndroid
      }
    }
    url
  }
`;

const alimTalkPreviewTemplateFragment = graphql`
  fragment AlimTalkPreview_alimTalkTemplate on AlimTalkTemplate {
    messageType
    emphasizeType
    adContent
    extraContent
    additionalTitle
    imageName
    imageUrl
  }
`;

type Props = {
  alimTalkPreview: AlimTalkPreview_alimTalkPreview$key;
  alimTalkTemplate: AlimTalkPreview_alimTalkTemplate$key;
} & ViewProps;

type KakaoBizMessageItemType = KakaoBizItemListMessageType['items'];

export const convertToKakaoBizMessage = (
  alimTalkPreviewMessage: AlimTalkPreview_alimTalkPreview$data['messages'][0],
  alimTalkTemplate: AlimTalkPreview_alimTalkTemplate$data,
  baseMessage: KakaoBizCommonMessageProps,
): KakaoBizMessageProps => {
  // Convert buttons to the expected format
  const buttons: Button[] = alimTalkPreviewMessage.buttons
    ? alimTalkPreviewMessage.buttons.map((btn) => ({
        type: btn.type as Button['type'],
        name: btn.name,
        linkMobile: btn.linkMobile || undefined,
        linkPc: btn.linkPc || undefined,
        schemeIos: btn.schemeIos || undefined,
        schemeAndroid: btn.schemeAndroid || undefined,
      }))
    : [];

  const commonProps = {
    content: alimTalkPreviewMessage.content || '',
    buttons,
    category: baseMessage.category,
    senderName: baseMessage.senderName,
    senderProfileImage: baseMessage.senderProfileImage,
    extraContent: baseMessage.extraContent,
    adContent: baseMessage.adContent,
  };

  switch (baseMessage.type) {
    case 'emphasis': {
      const emphasisMessage: KakaoBizEmphasisMessageType = {
        type: 'emphasis',
        title: alimTalkPreviewMessage.title || alimTalkTemplate?.additionalTitle || '',
        subtitle: alimTalkPreviewMessage.headerContent || '',
        ...commonProps,
        image: alimTalkTemplate.imageUrl || undefined,
      };
      return emphasisMessage;
    }

    case 'itemList': {
      const items: KakaoBizMessageItemType = [];

      if (alimTalkPreviewMessage.item && alimTalkPreviewMessage.item.list) {
        alimTalkPreviewMessage.item.list.forEach((item: any) => {
          items.push({
            title: item.title || '',
            description: item.description || '',
          });
        });
      }

      if (alimTalkPreviewMessage.item && alimTalkPreviewMessage.item.summary) {
        items.push({
          title: alimTalkPreviewMessage.item.summary.title || '',
          description: '',
          summary: alimTalkPreviewMessage.item.summary.description || '',
        });
      }

      const itemListMessage: KakaoBizItemListMessageType = {
        type: 'itemList',
        header: alimTalkPreviewMessage.headerContent || undefined,
        items:
          items.length > 0
            ? items
            : [
                { title: '항목', description: '내용', summary: '요약' },
                { title: '항목2', description: '내용2', summary: '요약2' },
              ],
        highlightText: alimTalkPreviewMessage.itemHighlight?.title,
        highlightDescription: alimTalkPreviewMessage.itemHighlight?.description,
        ...commonProps,
        image: alimTalkTemplate.imageUrl || undefined,
      };
      return itemListMessage;
    }

    case 'image': {
      const imageMessage: KakaoBizImageMessageType = {
        type: 'image',
        image: alimTalkTemplate.imageUrl || '',
        ...commonProps,
      };
      return imageMessage;
    }

    default: {
      const basicMessage: KakaoBizBasicMessageType = {
        type: 'basic',
        ...commonProps,
        image: alimTalkTemplate.imageUrl || undefined,
      };
      return basicMessage;
    }
  }
};

export const convertToKakaoBizMessages = (
  alimTalkPreviewMessages: AlimTalkPreview_alimTalkPreview$data['messages'],
  alimTalkTemplate: AlimTalkPreview_alimTalkTemplate$data,
): KakaoBizMessageProps[] => {
  const senderName = '수학대왕';
  const senderProfileImage =
    'https://play-lh.googleusercontent.com/Q9NkwDb-oR-rlbs_wYahWFP5HcfRCzXN-btp4z9k52fDlbe3RbCcIR_8bTKHwZtBn4c=s96-rw';

  let messageType: KakaoBizMessageTypes = 'basic';
  if (alimTalkTemplate?.emphasizeType) {
    if (alimTalkTemplate.emphasizeType === 'ITEM_LIST') {
      messageType = 'itemList';
    } else if (alimTalkTemplate.emphasizeType === 'TEXT') {
      messageType = 'emphasis';
    } else if (alimTalkTemplate.emphasizeType === 'IMAGE') {
      messageType = 'image';
    }
  }

  const baseMessage: KakaoBizCommonMessageProps = {
    type: messageType,
    category: '알림톡 도착',
    content: '',
    extraContent: alimTalkTemplate.extraContent || undefined,
    adContent: alimTalkTemplate.adContent || undefined,
    senderName,
    senderProfileImage,
    buttons: [],
  };

  return alimTalkPreviewMessages.map((msg) => convertToKakaoBizMessage(msg, alimTalkTemplate, baseMessage));
};

const AlimTalkPreview = ({
  alimTalkPreview: alimTalkPreviewReference,
  alimTalkTemplate: alimTalkPreviewTemplateReference,
  ...props
}: Props) => {
  const alimTalkPreview = useFragment(alimTalkPreviewFragment, alimTalkPreviewReference);
  const alimTalkPreviewTemplate = useFragment(alimTalkPreviewTemplateFragment, alimTalkPreviewTemplateReference);
  const { messages } = alimTalkPreview;

  const alimTalkPreviewMessageWithMetadata = messages.map((msg, index) => ({
    ...msg,
    id: index,
    ...alimTalkPreviewTemplate,
  }));

  return (
    <View {...props}>
      <Grid wrap={[true, true, false]} gapX={3} gapY={3}>
        <Grid.Unit size={[1, 1, 'min']}>
          <View sx={{ transformOrigin: 'left top', transform: 'scale(0.67)', height: 500 }}>
            <View marginRight={`-${(100 / 67) * 33}%`} marginBottom={`-${(100 / 67) * 33}%`}>
              <KakaoBizMessagePreview
                messages={convertToKakaoBizMessages(alimTalkPreview.messages.slice(0, 1), alimTalkPreviewTemplate)}
              />
            </View>
          </View>
        </Grid.Unit>
        <Grid.Unit size={[1, 1, 'max']}>
          <Card sx={{ padding: 4 }}>
            {alimTalkPreviewMessageWithMetadata?.[0] ? (
              <DescriptionList
                titleUnitSize={1 / 4}
                descriptionUnitSize={3 / 4}
                picks={[
                  'to',
                  'content',
                  'title',
                  'additionalTitle',
                  'headerContent',
                  'buttons',
                  'item',
                  'summary',
                  'itemHighlight',
                ]}
                item={alimTalkPreviewMessageWithMetadata[0]}
                itemDescriptions={{
                  to: {
                    title: '수신자',
                    renderValue: ({ to }) => (to ? <Text fontSize={1}>{to}</Text> : '-'),
                  },
                  content: {
                    title: '내용',
                    renderValue: ({ content }) => (content ? <Text fontSize={0}>{content}</Text> : '-'),
                  },
                  title: {
                    title: '제목',
                    renderValue: ({ title }) => (title ? <Text fontSize={1}>{title}</Text> : '-'),
                  },
                  additionalTitle: {
                    title: '추가 제목',
                    renderValue: ({ additionalTitle }) =>
                      additionalTitle ? <Text fontSize={1}>{additionalTitle}</Text> : '-',
                  },
                  headerContent: {
                    title: '헤더 내용',
                    renderValue: ({ headerContent }) =>
                      headerContent ? <Text fontSize={1}>{headerContent}</Text> : '-',
                  },
                  buttons: {
                    title: '버튼',
                    renderValue: ({ buttons }) =>
                      buttons?.length ? (
                        <View>
                          {buttons.map((btn, index) => (
                            <View key={index} mb={3}>
                              <View>
                                <Text fontWeight="bold">이름: </Text>
                                <Text>{btn.name}</Text>
                              </View>
                              <View mt={2}>
                                <Text fontWeight="bold">링크: </Text>
                                <Text whiteSpace={'pre-wrap'} sx={{ wordBreak: 'break-all' }}>
                                  {btn.linkMobile || btn.linkPc || btn.schemeIos || btn.schemeAndroid}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      ) : (
                        '-'
                      ),
                  },
                  item: {
                    title: '아이템',
                    renderValue: ({ item }) =>
                      item?.list?.length ? (
                        <View mb={2}>
                          {item.list.map((listItem, index) => (
                            <View key={index}>
                              <Text fontWeight={'bold'}>{listItem.title}: </Text>
                              <Text>{listItem.description}</Text>
                            </View>
                          ))}
                        </View>
                      ) : (
                        '-'
                      ),
                  },
                  summary: {
                    title: '아이템 요약',
                    renderValue: ({ item }) =>
                      item?.summary ? (
                        <View>
                          <Text fontWeight={'bold'}>제목: </Text>
                          <Text> {item.summary.title}</Text>
                          <Text fontWeight={'bold'}>내용: </Text>
                          <Text> {item.summary.description}</Text>
                        </View>
                      ) : (
                        '-'
                      ),
                  },

                  itemHighlight: {
                    title: '아이템 하이라이트',
                    renderValue: ({ itemHighlight }) =>
                      itemHighlight ? (
                        <View>
                          <Text fontWeight={'bold'}>제목: </Text>
                          <Text>{itemHighlight.title}</Text>
                          <Text fontWeight={'bold'}>내용: </Text>
                          <Text>{itemHighlight.description}</Text>
                        </View>
                      ) : (
                        '-'
                      ),
                  },
                }}
              />
            ) : (
              <EmptyState title={'입력된 알림톡이 없어요'} />
            )}
          </Card>
        </Grid.Unit>
      </Grid>
    </View>
  );
};

export default AlimTalkPreview;
