import { graphql, useFragment } from 'react-relay';

import {
  MATConsultationHistoryDescriptionList_matConsultationHistory$data,
  MATConsultationHistoryDescriptionList_matConsultationHistory$key,
} from '../../../relay/__generated__/MATConsultationHistoryDescriptionList_matConsultationHistory.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';

const MATConsultationHistoryDescriptionList_matConsultationHistory = graphql`
  fragment MATConsultationHistoryDescriptionList_matConsultationHistory on MATConsultationHistory {
    created
    nextContactDate
  }
`;

type Props = {
  matConsultationHistory: MATConsultationHistoryDescriptionList_matConsultationHistory$key;
  type?: 'default';
} & Omit<
  DescriptionListProps<MATConsultationHistoryDescriptionList_matConsultationHistory$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const MATConsultationHistoryDescriptionList = ({
  matConsultationHistory: matConsultationHistoryReference,
  type = 'default',
  ...props
}: Props) => {
  const consultationHistory = useFragment(
    MATConsultationHistoryDescriptionList_matConsultationHistory,
    matConsultationHistoryReference,
  );

  return (
    <DescriptionList
      item={consultationHistory}
      itemDescriptions={{
        created: {
          title: '상담 일시',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
        },
        nextContactDate: {
          title: '다음 컨택일',
          renderValue: ({ nextContactDate }) =>
            nextContactDate ? <DateText format={'P'}>{nextContactDate}</DateText> : '-',
        },
      }}
      picks={type === 'default' ? ['created', 'nextContactDate'] : []}
      {...props}
    />
  );
};

export default MATConsultationHistoryDescriptionList;
