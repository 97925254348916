import React from 'react';

import {
  KakaoBizMessageAdContent,
  KakaoBizMessageAdContentContainer,
  KakaoBizMessageButtonsContainer,
  KakaoBizMessageButtonText,
  KakaoBizMessageButtonTooltip,
  KakaoBizMessageCategoryHeader,
  KakaoBizMessageExtraContent,
  KakaoBizMessageExtraContentContainer,
  KakaoBizMessageMessageButton,
  KakaoBizMessageContainer,
} from './styles';

export type ButtonType = 'WL' | 'AL' | 'AC';

export type Button = {
  type: ButtonType;
  name: string;
  linkMobile?: string;
  linkPc?: string;
  schemeIos?: string | null;
  schemeAndroid?: string | null;
};

export type BaseMessageProps = {
  category: string;
  content: string;
  image?: string;
  extraContent?: string;
  adContent?: string;
  buttons?: Button[];
  isWide?: boolean;
};

type BaseMessageComponentProps = BaseMessageProps & {
  children: React.ReactNode;
};

const BaseMessage: React.FC<BaseMessageComponentProps> = ({ category, extraContent, adContent, buttons, children }) => {
  const handleButtonClick = (button: Button) => {
    if (button.type === 'WL') {
      window.open(button.linkMobile || button.linkPc, '_blank');
    } else if (button.type === 'AL') {
      const scheme = button.schemeIos || button.schemeAndroid;
      if (scheme) {
        window.location.href = scheme;
      }
    }
  };

  const hasACButton = buttons?.some((button) => button.type === 'AC');
  const finalAdContent = hasACButton ? '채널 추가하고 이 채널의 광고와 마케팅 메시지를 카카오톡으로 받기' : adContent;
  return (
    <KakaoBizMessageContainer>
      <KakaoBizMessageCategoryHeader>{category}</KakaoBizMessageCategoryHeader>
      {children}
      {extraContent && (
        <KakaoBizMessageExtraContentContainer>
          <KakaoBizMessageExtraContent>{extraContent}</KakaoBizMessageExtraContent>
        </KakaoBizMessageExtraContentContainer>
      )}
      {finalAdContent && (
        <KakaoBizMessageAdContentContainer>
          <KakaoBizMessageAdContent>{finalAdContent}</KakaoBizMessageAdContent>
        </KakaoBizMessageAdContentContainer>
      )}
      {buttons && buttons.length > 0 && (
        <KakaoBizMessageButtonsContainer>
          {buttons.map((button, index) => (
            <KakaoBizMessageMessageButton
              key={index}
              onClick={() => handleButtonClick(button)}
              buttonType={button.type}
            >
              <KakaoBizMessageButtonText buttonType={button.type}>{button.name}</KakaoBizMessageButtonText>
              <KakaoBizMessageButtonTooltip>
                {button.type === 'WL' && (button.linkMobile || button.linkPc)}
                {button.type === 'AL' && (button.schemeIos || button.schemeAndroid)}
                {button.type === 'AC' && '채널 추가하기'}
              </KakaoBizMessageButtonTooltip>
            </KakaoBizMessageMessageButton>
          ))}
        </KakaoBizMessageButtonsContainer>
      )}
    </KakaoBizMessageContainer>
  );
};

export default BaseMessage;
