/**
 * @generated SignedSource<<70a3f642bb930e18fbb71b23413592f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATSalesRecordsAssignInput = {
  assignedBy: string;
  assignedTo: string;
  salesRecords: ReadonlyArray<string>;
};
export type MATSalesRecordsAssignDialog_salesRecordsAssignMutation$variables = {
  input: MATSalesRecordsAssignInput;
};
export type MATSalesRecordsAssignDialog_salesRecordsAssignMutation$data = {
  readonly salesRecordsAssign: ReadonlyArray<{
    readonly assignedTo: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly id: string;
  }>;
};
export type MATSalesRecordsAssignDialog_salesRecordsAssignMutation = {
  response: MATSalesRecordsAssignDialog_salesRecordsAssignMutation$data;
  variables: MATSalesRecordsAssignDialog_salesRecordsAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATSalesRecord",
    "kind": "LinkedField",
    "name": "salesRecordsAssign",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MATTcmsUser",
        "kind": "LinkedField",
        "name": "assignedTo",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATSalesRecordsAssignDialog_salesRecordsAssignMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATSalesRecordsAssignDialog_salesRecordsAssignMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "41d0bdb0f46aadd5a880ebb5033da9c5",
    "id": null,
    "metadata": {},
    "name": "MATSalesRecordsAssignDialog_salesRecordsAssignMutation",
    "operationKind": "mutation",
    "text": "mutation MATSalesRecordsAssignDialog_salesRecordsAssignMutation(\n  $input: MATSalesRecordsAssignInput!\n) {\n  salesRecordsAssign(input: $input) {\n    id\n    assignedTo {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b819b0a28bdc2a6803f7d35f50fc5ad";

export default node;
