/**
 * @generated SignedSource<<e2516293313358f02c6b79fa9be08ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATOrganizationStudentsDeleteInput = {
  organizationId: string;
};
export type OrganizationId_organizationStudentsDeleteMutation$variables = {
  input: MATOrganizationStudentsDeleteInput;
};
export type OrganizationId_organizationStudentsDeleteMutation$data = {
  readonly organizationStudentsDelete: {
    readonly id: string;
  };
};
export type OrganizationId_organizationStudentsDeleteMutation = {
  response: OrganizationId_organizationStudentsDeleteMutation$data;
  variables: OrganizationId_organizationStudentsDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATOrganization",
    "kind": "LinkedField",
    "name": "organizationStudentsDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationId_organizationStudentsDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationId_organizationStudentsDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dee95064cda2c3ed94e90935dbc6fb46",
    "id": null,
    "metadata": {},
    "name": "OrganizationId_organizationStudentsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationId_organizationStudentsDeleteMutation(\n  $input: MATOrganizationStudentsDeleteInput!\n) {\n  organizationStudentsDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f8edb934f02a98e5701db2ce330deb5";

export default node;
