import styled from 'styled-components';

import BaseMessage, { BaseMessageProps } from './BaseMessage';
import { KakaoBizMessageContent, KakaoBizMessageContentContainer, KakaoBizMessageImageContainer } from './styles';
import { kakaoTheme } from './theme';

type EmphasisMessageProps = BaseMessageProps & {
  title: string;
  subtitle: string;
  buttons?: BaseMessageProps['buttons'];
};

const TitleContainer = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
`;

const Title = styled.h3`
  font-size: ${kakaoTheme.fontSize.lg};
  font-weight: ${kakaoTheme.fontWeight.semibold};
  color: ${kakaoTheme.colors.kakao.text};
  margin: 0;
`;

const Subtitle = styled.h4`
  font-size: ${kakaoTheme.fontSize.base};
  font-weight: ${kakaoTheme.fontWeight.medium};
  color: ${kakaoTheme.colors.kakao.secondary};
  margin: 0.25rem 0 0 0;
`;

const EmphasisMessage: React.FC<EmphasisMessageProps> = (props) => {
  const { content, title, subtitle, image, ...rest } = props;

  return (
    <BaseMessage {...rest} content={content}>
      {image && <KakaoBizMessageImageContainer src={image} alt="" />}
      <TitleContainer>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </TitleContainer>
      <KakaoBizMessageContentContainer>
        <KakaoBizMessageContent>{content}</KakaoBizMessageContent>
      </KakaoBizMessageContentContainer>
    </BaseMessage>
  );
};

export default EmphasisMessage;
