import { SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { debounce } from 'lodash-es';
import { Suspense, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useCaptureHtml from '../../../hooks/useCaptureHtml';
import useToast from '../../../hooks/useToast';
import { QuizImageExportDialog_quizzesQuery } from '../../../relay/__generated__/QuizImageExportDialog_quizzesQuery.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Switch from '../../core/Switch';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';
import View from '../../core/View';

const quizzesForQuizImageExportDialog = graphql`
  query QuizImageExportDialog_quizzesQuery($filters: QuizFilter) {
    quizzes(filters: $filters) {
      edges {
        node {
          id
          sequence
          problem
          solution
          tip
        }
      }
    }
  }
`;

type Props = {} & DialogProps;

const CONTAINER_CLASSNAME = '캡쳐해야하는요소';

const QuizImageExportDialog = ({ ...props }: Props) => {
  const { theme } = useTheme();
  const { toast } = useToast();

  const [queryReference, loadQuery] = useQueryLoader<QuizImageExportDialog_quizzesQuery>(
    quizzesForQuizImageExportDialog,
  );
  const currentQuizSequence = queryReference?.variables.filters?.sequence_Exact;

  const [isNewFont, setIsNewFont] = useState(true);

  const [width, setWidth] = useState<number>(500);
  const [scale, setScale] = useState<number>(10);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { downloadImageFromHtml } = useCaptureHtml({
    scale: 2,
    backgroundColor: theme?.colorSchemes.light.colors.canvas.default,
  });

  const handleClickExport = () => {
    const containers = document.getElementsByClassName(CONTAINER_CLASSNAME);

    if (!containers || containers.length === 0) {
      return;
    }
    setIsLoading(true);

    if (!containers || containers.length === 0) return;

    setIsLoading(true);

    Promise.all(
      Array.from(containers).map((container) =>
        downloadImageFromHtml(container as HTMLElement, container.id, { scale }),
      ),
    )
      .catch(() => {
        toast('이미지 추출에 실패했습니다', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog {...props}>
      <Dialog.Header>퀴즈 이미지 추출하기</Dialog.Header>
      <Dialog.Body>
        <Stack gapX={3}>
          <Stack.Item>
            <TextInput
              leadingVisual={SearchIcon}
              placeholder={'ID 검색'}
              defaultValue={currentQuizSequence || ''}
              onChange={debounce((e) => {
                if (!isNaN(Number(e.target.value))) {
                  loadQuery({ filters: { sequence_Exact: Number(e.target.value) } });
                }
              }, 500)}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack gapX={1}>
              <Stack.Item>
                <Text fontSize={1}>폰트</Text>
              </Stack.Item>
              <Stack.Item>
                <Switch value={isNewFont} onChange={setIsNewFont} />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack gapX={1}>
              <Stack.Item>
                <Text fontSize={1}>width :</Text>
              </Stack.Item>
              <Stack.Item>
                <TextInput
                  type={'number'}
                  defaultValue={width}
                  onChange={debounce((e) => setWidth(Number(e.target.value)), 500)}
                  sx={{ width: 100 }}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <TextInput
              leadingVisual={'scale'}
              type={'number'}
              value={scale}
              onChange={(e) => setScale(Number(e.target.value))}
              sx={{ width: 100 }}
            />
          </Stack.Item>
          <Stack.Item>
            <Button onClick={() => handleClickExport()} disabled={!currentQuizSequence || isLoading}>
              추출하기
            </Button>
          </Stack.Item>
          {isLoading ? (
            <Stack.Item>
              <Spinner size={'small'} />
            </Stack.Item>
          ) : null}
        </Stack>
        {queryReference ? (
          <ErrorBoundary
            key={queryReference.fetchKey}
            fallback={({ parsedGraphQLError }) => (
              <View sx={{ padding: 5 }}>
                <ErrorState description={parsedGraphQLError?.[0].message} />
              </View>
            )}
          >
            <Suspense
              fallback={
                <View sx={{ padding: 5 }}>
                  <Spinner />
                </View>
              }
            >
              <PreloadedQueryRenderer<QuizImageExportDialog_quizzesQuery>
                query={quizzesForQuizImageExportDialog}
                queryReference={queryReference}
              >
                {({ quizzes }) => {
                  const quiz = quizzes.edges[0]?.node;

                  if (!quiz)
                    return (
                      <View sx={{ padding: 5 }}>
                        <EmptyState title={'검색된 퀴즈가 없어요'} />
                      </View>
                    );

                  return (
                    <ItemList
                      renderItem={({ text, field }) => (
                        <>
                          <Text fontSize={1} fontWeight={'bold'} sx={{ marginTop: 3 }}>
                            {field}
                          </Text>
                          <Card
                            sx={{
                              borderRadius: 0,
                              width: 'fit-content',
                              maxWidth: '100%',
                              overflowX: 'auto',
                              marginTop: 1,
                            }}
                          >
                            <View
                              id={`quiz_${quiz.sequence}_${field}`}
                              className={`${CONTAINER_CLASSNAME}`}
                              sx={{ width: width || '100%', lineHeight: 0 }}
                              data-color-mode={'light'}
                              data-light-theme={'light'}
                            >
                              <Katex sx={isNewFont ? { fontFamily: 'NEXON Lv1 Gothic OTF' } : undefined}>{text}</Katex>
                            </View>
                          </Card>
                        </>
                      )}
                      items={[
                        { text: quiz.problem, field: 'problem' },
                        { text: quiz.solution, field: 'solution' },
                        { text: quiz.tip, field: 'tip' },
                      ]}
                    />
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          </ErrorBoundary>
        ) : (
          <View sx={{ padding: 5 }}>
            <EmptyState title={'문제를 검색해 주세요'} />
          </View>
        )}
      </Dialog.Body>
    </Dialog>
  );
};

export default QuizImageExportDialog;
