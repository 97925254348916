/**
 * @generated SignedSource<<57da2c68810335d0f2fb36f9b51765e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATSalesRecordStatusEnum = "absent" | "contract_planned" | "contract_succeed" | "exclude" | "feature_show" | "follow_up_required" | "ongoing" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATSalesRecordStatusUpdateActionList_matSalesRecord$data = {
  readonly id: string;
  readonly status: MATSalesRecordStatusEnum | null;
  readonly " $fragmentType": "MATSalesRecordStatusUpdateActionList_matSalesRecord";
};
export type MATSalesRecordStatusUpdateActionList_matSalesRecord$key = {
  readonly " $data"?: MATSalesRecordStatusUpdateActionList_matSalesRecord$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordStatusUpdateActionList_matSalesRecord">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATSalesRecordStatusUpdateActionList_matSalesRecord",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "MATSalesRecord",
  "abstractKey": null
};

(node as any).hash = "0c54a99550275f9a129b8a78dfe25faf";

export default node;
