/**
 * @generated SignedSource<<56d6ac44fdf550ca2a196325cf1b8cee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATSchoolYearTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type MathKingUserId_mathKingUserQuery$variables = {
  id: string;
};
export type MathKingUserId_mathKingUserQuery$data = {
  readonly internalUser: {
    readonly id: string;
    readonly loginId: string;
    readonly phoneNumber: string | null;
    readonly studentProfile: {
      readonly friendTag: string;
      readonly profileImageUrl: string;
      readonly schoolYearType: MATSchoolYearTypeEnum;
      readonly statusMessage: string | null;
    };
    readonly userName: string;
    readonly userSequence: number;
    readonly " $fragmentSpreads": FragmentRefs<"InternalLoginTypeUpdateDialog_internalUser" | "InternalUserDescriptionList_internalUser">;
  };
};
export type MathKingUserId_mathKingUserQuery = {
  response: MathKingUserId_mathKingUserQuery$data;
  variables: MathKingUserId_mathKingUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSequence",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loginId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schoolYearType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendTag",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusMessage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MathKingUserId_mathKingUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATInternalUser",
        "kind": "LinkedField",
        "name": "internalUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInternalStudentProfile",
            "kind": "LinkedField",
            "name": "studentProfile",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternalUserDescriptionList_internalUser"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternalLoginTypeUpdateDialog_internalUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MathKingUserId_mathKingUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATInternalUser",
        "kind": "LinkedField",
        "name": "internalUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInternalStudentProfile",
            "kind": "LinkedField",
            "name": "studentProfile",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schoolYearMajorType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MATOrganization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e9c62ebfd34afc2681b651b34e003f7",
    "id": null,
    "metadata": {},
    "name": "MathKingUserId_mathKingUserQuery",
    "operationKind": "query",
    "text": "query MathKingUserId_mathKingUserQuery(\n  $id: ID!\n) {\n  internalUser(id: $id) {\n    id\n    userSequence\n    userName\n    loginId\n    phoneNumber\n    studentProfile {\n      schoolYearType\n      profileImageUrl\n      friendTag\n      statusMessage\n    }\n    ...InternalUserDescriptionList_internalUser\n    ...InternalLoginTypeUpdateDialog_internalUser\n  }\n}\n\nfragment InternalLoginTypeUpdateDialog_internalUser on MATInternalUser {\n  id\n  loginId\n}\n\nfragment InternalUserDescriptionList_internalUser on MATInternalUser {\n  id\n  userSequence\n  userName\n  loginId\n  phoneNumber\n  studentProfile {\n    schoolYearMajorType\n    profileImageUrl\n    friendTag\n    statusMessage\n  }\n  organization {\n    id\n    title\n  }\n  ...InternalLoginTypeUpdateDialog_internalUser\n  ...InternalUserNameUpdateDialog_internalUser\n  ...InternalUserPhoneNumberUpdateDialog_internalUser\n  ...InternalUserStatusMessageUpdateDialog_internalUser\n  ...InternalUserSchoolYearMajorTypeUpdateDialog_internalUser\n}\n\nfragment InternalUserNameUpdateDialog_internalUser on MATInternalUser {\n  id\n  userName\n}\n\nfragment InternalUserPhoneNumberUpdateDialog_internalUser on MATInternalUser {\n  id\n  phoneNumber\n}\n\nfragment InternalUserSchoolYearMajorTypeUpdateDialog_internalUser on MATInternalUser {\n  id\n  studentProfile {\n    schoolYearMajorType\n  }\n}\n\nfragment InternalUserStatusMessageUpdateDialog_internalUser on MATInternalUser {\n  id\n  studentProfile {\n    statusMessage\n  }\n}\n"
  }
};
})();

(node as any).hash = "4dc4c4b2e3193101397976b3479d0ff9";

export default node;
