import styled from 'styled-components';

import { kakaoTheme } from './theme';

export const KakaoBizMessageContainer = styled.div`
  background-color: white;
  border-radius: ${kakaoTheme.borderRadius['2xl']};
  box-shadow: ${kakaoTheme.shadows.sm};
  overflow: visible;
`;

export const KakaoBizMessageCategoryHeader = styled.div`
  background-color: ${kakaoTheme.colors.kakao.yellow};
  padding: 0.75rem 1rem;
  color: ${kakaoTheme.colors.kakao.text};
  font-weight: ${kakaoTheme.fontWeight.medium};
  border-top-left-radius: ${kakaoTheme.borderRadius['2xl']};
  border-top-right-radius: ${kakaoTheme.borderRadius['2xl']};
`;

export const KakaoBizMessageImageContainer = styled.img`
  width: 100%;
  height: auto;
`;

export const KakaoBizMessageContentContainer = styled.div`
  padding: 1rem;
`;

export const KakaoBizMessageContent = styled.p`
  color: ${kakaoTheme.colors.kakao.secondary};
  white-space: pre-line;
  margin: 0;
`;

export const KakaoBizMessageExtraContentContainer = styled.div`
  padding: 0 1rem 0.75rem 1rem;
`;

export const KakaoBizMessageExtraContent = styled.p`
  font-size: 13px;
  color: #999999;
  white-space: pre-line;
  margin: 0;
`;

export const KakaoBizMessageAdContentContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
`;

export const KakaoBizMessageAdContent = styled.p`
  font-size: 13px;
  color: #999999;
  white-space: pre-line;
  margin: 0;
`;

// Button related styles
export const KakaoBizMessageButtonsContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const KakaoBizMessageMessageButton = styled.button<{ buttonType: string }>`
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: center;
  border-radius: ${kakaoTheme.borderRadius.xl};
  border: 1px solid;
  transition: colors 0.2s;
  position: relative;

  ${(props) => {
    switch (props.buttonType) {
      case 'AC':
        return `
          background-color: ${kakaoTheme.colors.kakao.yellow};
          border-color: ${kakaoTheme.colors.kakao.yellow};
          &:hover {
            background-color: rgba(253, 230, 10, 0.9);
          }
        `;
      case 'WL':
      case 'AL':
        return `
          background-color: #f5f5f3;
          border-color: ${kakaoTheme.colors.gray[200]};
          &:hover {
            background-color: rgba(245, 245, 243, 0.9);
          }
        `;
      default:
        return '';
    }
  }}
`;

export const KakaoBizMessageButtonText = styled.span<{ buttonType: string }>`
  font-size: ${kakaoTheme.fontSize.base};
  ${(props) =>
    props.buttonType === 'AC'
      ? `
    color: ${kakaoTheme.colors.kakao.text};
    font-weight: ${kakaoTheme.fontWeight.medium};
  `
      : `
    color: ${kakaoTheme.colors.gray[700]};
  `}
`;

export const KakaoBizMessageButtonTooltip = styled.div`
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 0.5rem;
  background-color: ${kakaoTheme.colors.gray[800]};
  color: white;
  font-size: ${kakaoTheme.fontSize.xs};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  transition: opacity 0.2s;

  ${KakaoBizMessageMessageButton}:hover & {
    opacity: 1;
  }
`;
