/**
 * @generated SignedSource<<478a1722cbbe62da235a68381db72700>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type TemplateInspectionStatusEnum = "APR" | "REG" | "REJ" | "REQ" | "%future added value";
export type AlimTalkTemplateFilter = {
  AND?: AlimTalkTemplateFilter | null;
  DISTINCT?: boolean | null;
  NOT?: AlimTalkTemplateFilter | null;
  OR?: AlimTalkTemplateFilter | null;
  isDormant_Exact?: boolean | null;
  search?: string | null;
  templateInspectionStatus_Exact?: TemplateInspectionStatusEnum | null;
  templateInspectionStatus_In?: ReadonlyArray<TemplateInspectionStatusEnum> | null;
};
export type AlimTalkTemplateOrder = {
  createTime?: Ordering | null;
  id?: Ordering | null;
};
export type AlimTalkTemplateWindowPaginatorRefreshQuery$variables = {
  filters?: AlimTalkTemplateFilter | null;
  order?: AlimTalkTemplateOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type AlimTalkTemplateWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AlimTalkTemplateWindowPaginator_query">;
};
export type AlimTalkTemplateWindowPaginatorRefreshQuery = {
  response: AlimTalkTemplateWindowPaginatorRefreshQuery$data;
  variables: AlimTalkTemplateWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AlimTalkTemplateWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AlimTalkTemplateWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlimTalkTemplateWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AlimTalkTemplateConnection",
        "kind": "LinkedField",
        "name": "alimTalkTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AlimTalkTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AlimTalkTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "messageType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emphasizeType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateInspectionStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f28879a77fd3aed2921c4420649f43fb",
    "id": null,
    "metadata": {},
    "name": "AlimTalkTemplateWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query AlimTalkTemplateWindowPaginatorRefreshQuery(\n  $filters: AlimTalkTemplateFilter\n  $order: AlimTalkTemplateOrder\n  $page: Int = 1\n  $pageSize: Int\n) {\n  ...AlimTalkTemplateWindowPaginator_query_36liK3\n}\n\nfragment AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection on AlimTalkTemplateConnection {\n  edges {\n    node {\n      id\n      templateCode\n      templateName\n      messageType\n      emphasizeType\n      templateStatus\n      templateInspectionStatus\n      createTime\n    }\n  }\n}\n\nfragment AlimTalkTemplateWindowPaginator_query_36liK3 on Query {\n  alimTalkTemplates(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...AlimTalkTemplateConnectionDataTable_alimTalkTemplateConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n"
  }
};
})();

(node as any).hash = "777e12872ef956cfeb74fc115df1f11f";

export default node;
