/**
 * @generated SignedSource<<7f21bacce6e0c4135b9151851c2d796d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATOrganizationStudentSizeEnum = "fifty_one_to_two_hundred" | "over_two_hundred" | "ten_to_fifty" | "under_ten" | "%future added value";
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
export type MATSalesRecordPriorityEnum = "high" | "highest" | "low" | "lowest" | "medium" | "%future added value";
export type MATSalesRecordRegionEnum = "b01" | "b02" | "b03" | "b04" | "b05" | "b06" | "b07" | "b08" | "b09" | "b10" | "b11" | "b12" | "b13" | "b14" | "b15" | "b16" | "cb01" | "cb02" | "cb03" | "cb04" | "cb05" | "cb06" | "cb07" | "cb08" | "cb09" | "cb10" | "cb11" | "cn01" | "cn02" | "cn03" | "cn04" | "cn05" | "cn06" | "cn07" | "cn08" | "cn09" | "cn10" | "cn11" | "cn12" | "cn13" | "cn14" | "cn15" | "d01" | "d02" | "d03" | "d04" | "d05" | "d06" | "d07" | "d08" | "dj01" | "dj02" | "dj03" | "dj04" | "dj05" | "g01" | "g02" | "g03" | "g04" | "g05" | "gb01" | "gb02" | "gb03" | "gb04" | "gb05" | "gb06" | "gb07" | "gb08" | "gb09" | "gb10" | "gb11" | "gb12" | "gb13" | "gb14" | "gb15" | "gb16" | "gb17" | "gb18" | "gb19" | "gb20" | "gb21" | "gg01" | "gg02" | "gg03" | "gg04" | "gg05" | "gg06" | "gg07" | "gg08" | "gg09" | "gg10" | "gg11" | "gg12" | "gg13" | "gg14" | "gg15" | "gg16" | "gg17" | "gg18" | "gg19" | "gg20" | "gg21" | "gg22" | "gg23" | "gg24" | "gg25" | "gg26" | "gg27" | "gg28" | "gg29" | "gg30" | "gg31" | "gn01" | "gn02" | "gn03" | "gn04" | "gn05" | "gn06" | "gn07" | "gn08" | "gn09" | "gn10" | "gn11" | "gn12" | "gn13" | "gn14" | "gn15" | "gn16" | "gn17" | "gn18" | "i01" | "i02" | "i03" | "i04" | "i05" | "i06" | "i07" | "i08" | "i09" | "i10" | "jb01" | "jb02" | "jb03" | "jb04" | "jb05" | "jb06" | "jb07" | "jb08" | "jb09" | "jb10" | "jb11" | "jb12" | "jb13" | "jb14" | "jj01" | "jj02" | "jn01" | "jn02" | "jn03" | "jn04" | "jn05" | "jn06" | "jn07" | "jn08" | "jn09" | "jn10" | "jn11" | "jn12" | "jn13" | "jn14" | "jn15" | "jn16" | "jn17" | "jn18" | "jn19" | "kw01" | "kw02" | "kw03" | "kw04" | "kw05" | "kw06" | "kw07" | "kw08" | "kw09" | "kw10" | "kw11" | "kw12" | "kw13" | "kw14" | "kw15" | "kw16" | "kw17" | "s01" | "s02" | "s03" | "s04" | "s05" | "s06" | "s07" | "s08" | "s09" | "s10" | "s11" | "s12" | "s13" | "s14" | "s15" | "s16" | "s17" | "s18" | "s19" | "s20" | "s21" | "s22" | "s23" | "s24" | "s25" | "sj01" | "u01" | "u02" | "u03" | "u04" | "u05" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATSalesRecordConnectionDataTable_matSalesRecordConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly assignedTo: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly consultationHistories: ReadonlyArray<{
        readonly created: any;
        readonly description: string;
        readonly id: string;
        readonly nextContactDate: any | null;
      }>;
      readonly description: string | null;
      readonly id: string;
      readonly latestAcquisitionChannel: {
        readonly created: any;
        readonly id: string;
        readonly utmSource: string | null;
      } | null;
      readonly name: string;
      readonly organization: {
        readonly id: string;
      } | null;
      readonly organizationStudentSize: MATOrganizationStudentSizeEnum | null;
      readonly organizationType: MATOrganizationTypeEnum | null;
      readonly phoneNumber: string | null;
      readonly priority: MATSalesRecordPriorityEnum | null;
      readonly region: MATSalesRecordRegionEnum | null;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordPriorityUpdateActionList_matSalesRecord" | "MATSalesRecordStatusLabel_matSalesRecord" | "MATSalesRecordStatusUpdateActionList_matSalesRecord">;
    };
  }>;
  readonly " $fragmentType": "MATSalesRecordConnectionDataTable_matSalesRecordConnection";
};
export type MATSalesRecordConnectionDataTable_matSalesRecordConnection$key = {
  readonly " $data"?: MATSalesRecordConnectionDataTable_matSalesRecordConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordConnectionDataTable_matSalesRecordConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATSalesRecordConnectionDataTable_matSalesRecordConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MATSalesRecordEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MATSalesRecord",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MATSalesRecordStatusLabel_matSalesRecord"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MATSalesRecordStatusUpdateActionList_matSalesRecord"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MATSalesRecordPriorityUpdateActionList_matSalesRecord"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MATOrganization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MATConsultationHistory",
              "kind": "LinkedField",
              "name": "consultationHistories",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nextContactDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationStudentSize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "region",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MATTcmsUser",
              "kind": "LinkedField",
              "name": "assignedTo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MATAcquisitionChannel",
              "kind": "LinkedField",
              "name": "latestAcquisitionChannel",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "utmSource",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MATSalesRecordConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0d76acf5d72c77a50222d5447cb36d20";

export default node;
