import { KebabHorizontalIcon } from '@primer/octicons-react';
import { ChevronLeftIcon, SearchIcon } from '@teamturing/icons';
import { useRef } from 'react';
import styled from 'styled-components';

import Avatar from '../../core/Avatar';

import { Button } from './messages/BaseMessage';
import BasicMessage from './messages/BasicMessage';
import EmphasisMessage from './messages/EmphasisMessage';
import ImageMessage from './messages/ImageMessage';
import ItemListMessage from './messages/ItemListMessage';
import { kakaoTheme } from './messages/theme';

export type KakaoBizMessageTypes = 'basic' | 'image' | 'emphasis' | 'itemList' | 'carousel';

export type KakaoBizCommonMessageProps = {
  type: KakaoBizMessageTypes;
  category: string;
  content: string;
  extraContent?: string;
  adContent?: string;
  buttons?: Button[];
  senderName: string;
  senderProfileImage: string;
  isWide?: boolean;
};

export type KakaoBizBasicMessageType = KakaoBizCommonMessageProps & {
  type: 'basic';
  image?: string;
};

export type KakaoBizImageMessageType = KakaoBizCommonMessageProps & {
  type: 'image';
  image: string;
};

export type KakaoBizEmphasisMessageType = KakaoBizCommonMessageProps & {
  type: 'emphasis';
  title: string;
  subtitle: string;
  image?: string;
};

export type KakaoBizItemListMessageType = KakaoBizCommonMessageProps & {
  type: 'itemList';
  header?: string;
  items: Array<{
    title: string;
    description?: string;
    summary?: string;
  }>;
  image?: string;
  highlightImage?: string;
  highlightText?: string;
  highlightDescription?: string;
};

export type KakaoBizCarouselMessageType = Omit<KakaoBizCommonMessageProps, 'content'> & {
  type: 'carousel';
  items: Array<{
    title: string;
    description?: string;
    image?: string;
  }>;
};

export type KakaoBizMessageProps =
  | KakaoBizBasicMessageType
  | KakaoBizImageMessageType
  | KakaoBizEmphasisMessageType
  | KakaoBizItemListMessageType
  | KakaoBizCarouselMessageType;

type KakaoBizMessagePreviewProps = {
  messages: KakaoBizMessageProps[];
};

const Container = styled.div`
  margin: 0 auto;

  max-width: 400px;

  background-color: ${kakaoTheme.colors.custom.blue};

  width: 100%;
  height: 100%;
  aspect-ratio: 0.5625;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: ${kakaoTheme.borderRadius['2xl']};
`;

const Header = styled.div`
  background-color: ${kakaoTheme.colors.custom.blue};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //z-index: 1;
`;

const HeaderLeft = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  color: ${kakaoTheme.colors.gray[600]};
`;

const HeaderCenter = styled.div`
  flex: 1;
  text-align: center;
`;

const HeaderTitle = styled.div`
  font-weight: ${kakaoTheme.fontWeight.medium};
  color: ${kakaoTheme.colors.gray[900]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderTitleText = styled.span`
  font-weight: ${kakaoTheme.fontWeight.bold};
`;

const HeaderTitleBadge = styled.span`
  margin-left: 0.25rem;
  font-size: ${kakaoTheme.fontSize.xs};
  background-color: ${kakaoTheme.colors.gray[200]};
  color: ${kakaoTheme.colors.gray[600]};
  padding: 0 0.25rem;
  border-radius: 0.25rem;
`;

const HeaderSubtitle = styled.div`
  font-size: ${kakaoTheme.fontSize.xs};
  color: ${kakaoTheme.colors.gray[500]};
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const HeaderIcon = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  color: ${kakaoTheme.colors.gray[600]};
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${kakaoTheme.colors.custom.blue};
`;

const MessageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 0 1rem 0;
  animation: messageAppear 0.3s ease-out forwards;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const SenderName = styled.span`
  font-size: ${kakaoTheme.fontSize.sm};
  color: ${kakaoTheme.colors.gray[600]};
  display: block;
  margin-bottom: 0.25rem;
`;

const MessageContent = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: ${({ isFullWidth }) => (isFullWidth ? 'column' : 'row')};
  align-items: ${({ isFullWidth }) => (isFullWidth ? 'flex-start' : 'flex-end')};
  gap: 0.5rem;
`;

const ContentContainer = styled.div<{ widthPercent?: number }>`
  flex: 1;
  width: ${({ widthPercent }) => widthPercent || 80}%;
  max-width: ${({ widthPercent }) => widthPercent || 80}%;
`;

const MessageBubble = styled.div`
  background-color: white;
  border-radius: ${kakaoTheme.borderRadius['2xl']};
  box-shadow: ${kakaoTheme.shadows.sm};
  overflow: hidden;
`;

const TimeStamp = styled.span<{ isFullWidth?: boolean }>`
  font-size: ${kakaoTheme.fontSize.xs};
  color: ${kakaoTheme.colors.gray[400]};
  margin-bottom: 1rem;
  align-self: ${({ isFullWidth }) => (isFullWidth ? 'flex-end' : 'auto')};
  margin-top: ${({ isFullWidth }) => (isFullWidth ? '0.25rem' : '0')};
`;

const Message = (props: KakaoBizMessageProps) => {
  const messageContent = () => {
    const { type, category, extraContent, adContent, buttons } = props;
    if (type === 'carousel') {
      return null;
    }

    const content = 'content' in props ? props.content : '';

    switch (type) {
      case 'basic':
        return (
          <BasicMessage
            category={category}
            content={content}
            image={props.image}
            extraContent={extraContent}
            adContent={adContent}
            buttons={buttons}
          />
        );
      case 'image':
        return (
          <ImageMessage
            category={category}
            content={content}
            image={props.image}
            extraContent={extraContent}
            adContent={adContent}
            buttons={buttons}
          />
        );
      case 'emphasis':
        return (
          <EmphasisMessage
            category={category}
            content={content}
            title={props.title}
            subtitle={props.subtitle}
            image={props.image}
            extraContent={extraContent}
            adContent={adContent}
            buttons={buttons}
          />
        );
      case 'itemList':
        return (
          <ItemListMessage
            category={category}
            content={content}
            header={props.header}
            items={props.items}
            image={props.image}
            highlightImage={props.highlightImage}
            highlightText={props.highlightText}
            highlightDescription={props.highlightDescription}
            extraContent={extraContent}
            adContent={adContent}
            buttons={buttons}
          />
        );
    }
  };
  const widthPercent = props.isWide ? 100 : 80;

  return (
    <MessageContainer>
      <MessageWrapper>
        <Avatar src={props.senderProfileImage} alt={props.senderName} square={false} />
        <ContentWrapper>
          <SenderName>{props.senderName}</SenderName>
          <MessageContent isFullWidth={widthPercent === 100}>
            <ContentContainer widthPercent={widthPercent}>
              <MessageBubble>{messageContent()}</MessageBubble>
            </ContentContainer>
            <TimeStamp isFullWidth={widthPercent === 100}>오후 11:13</TimeStamp>
          </MessageContent>
        </ContentWrapper>
      </MessageWrapper>
    </MessageContainer>
  );
};

const KakaoBizMessagePreview: React.FC<KakaoBizMessagePreviewProps> = ({ messages: children }) => {
  const messagesRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <ChevronLeftIcon />
        </HeaderLeft>
        <HeaderCenter>
          <HeaderTitle>
            <HeaderTitleText>수학대왕</HeaderTitleText>
            <HeaderTitleBadge>✓</HeaderTitleBadge>
          </HeaderTitle>
          <HeaderSubtitle>070-732-4869</HeaderSubtitle>
        </HeaderCenter>
        <HeaderRight>
          <HeaderIcon>
            <SearchIcon />
          </HeaderIcon>
          <HeaderIcon>
            <KebabHorizontalIcon />
          </HeaderIcon>
        </HeaderRight>
      </Header>

      <MessagesContainer ref={messagesRef}>
        {children.map((message, index) => {
          return <Message key={index} {...message} />;
        })}
      </MessagesContainer>
    </Container>
  );
};

export default KakaoBizMessagePreview;
