import { SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { debounce } from 'lodash-es';
import React, { Suspense, useEffect, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useCaptureHtml from '../../../hooks/useCaptureHtml';
import useToast from '../../../hooks/useToast';
import { FormulaSetImageExportDialog_formulaSetsQuery } from '../../../relay/__generated__/FormulaSetImageExportDialog_formulaSetsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import CopyableText from '../../core/CopyableText';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';
import View from '../../core/View';

const formulaSetsForFormulaSetImageExportDialog = graphql`
  query FormulaSetImageExportDialog_formulaSetsQuery($filters: FormulaSetFilter) {
    formulaSets(filters: $filters) {
      edges {
        node {
          id
          sequence
          unitD {
            id
            description
          }
          formulas {
            edges {
              node {
                id
                title
                text
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {} & DialogProps;

const CONTAINER_CLASSNAME = 'formula-set-capture';

const FormulaSetImageExportDialog = ({ ...props }: Props) => {
  const { theme } = useTheme();
  const { toast } = useToast();

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<FormulaSetImageExportDialog_formulaSetsQuery>(
    formulaSetsForFormulaSetImageExportDialog,
  );

  const [width, setWidth] = useState<number>(500);
  const [scale, setScale] = useState<number>(10);

  const [urls, setUrls] = useState<string[]>([]);
  useEffect(() => {
    setUrls([]);
  }, [queryReference]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { downloadImageFromHtml } = useCaptureHtml({
    scale: 1.5,
    backgroundColor: theme?.colorSchemes.light.colors.canvas.default,
  });

  const handleClickExport = async (fileName: string) => {
    const containers = document.getElementsByClassName(CONTAINER_CLASSNAME);
    if (!containers || containers.length === 0) return;

    setIsLoading(true);

    Promise.all(
      Array.from(containers).map((container, index) =>
        downloadImageFromHtml(container as HTMLElement, `${fileName || 'image'}_${index + 1}`, { scale }),
      ),
    )
      .catch(() => {
        toast('이미지 추출에 실패했습니다', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  // const handleClickUpload = (fileName: string) => {
  //   const containers = document.getElementsByClassName(CONTAINER_CLASSNAME);
  //   if (!containers || containers.length === 0) {
  //     return;
  //   }
  //   setIsLoading(true);
  //
  //   Promise.all(
  //     Array.from(containers).map((container, index) =>
  //       uploadImageFromHtml(container as HTMLElement, `${fileName || 'image'}_${index + 1}`).then(
  //         (res) => res.metaData.object_url,
  //       ),
  //     ),
  //   )
  //     .then((urls) => setUrls(urls))
  //     .catch(() => toast('추출에 실패했습니다', 'error'))
  //     .finally(() => setIsLoading(false));
  // };

  return (
    <Dialog {...props}>
      <Dialog.Header>개념집 이미지 추출하기</Dialog.Header>
      <Dialog.Body>
        <>
          <Stack gapX={3}>
            <Stack.Item>
              <TextInput
                leadingVisual={SearchIcon}
                placeholder={'ID 검색'}
                onChange={debounce((e) => {
                  const value = Number(e.target.value);
                  if (!isNullable(e.target.value) && !isNaN(value)) {
                    loadQuery({ filters: { sequence_Exact: value } });
                  } else {
                    disposeQuery();
                  }
                }, 500)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack gapX={1}>
                <Stack.Item>
                  <Text fontSize={1}>width :</Text>
                </Stack.Item>
                <Stack.Item>
                  <TextInput
                    type={'number'}
                    value={width}
                    onChange={(e) => setWidth(Number(e.target.value))}
                    sx={{ width: 100 }}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextInput
                leadingVisual={'scale'}
                type={'number'}
                value={scale}
                onChange={(e) => setScale(Number(e.target.value))}
                sx={{ width: 100 }}
              />
            </Stack.Item>
            {queryReference ? (
              <Suspense>
                <PreloadedQueryRenderer
                  query={formulaSetsForFormulaSetImageExportDialog}
                  queryReference={queryReference}
                >
                  {({ formulaSets }) => {
                    const formulaSet = formulaSets.edges[0]?.node;

                    if (!formulaSet) return null;

                    const fileName = formulaSet.sequence + '';

                    return (
                      <>
                        <Stack.Item>
                          <Button onClick={() => handleClickExport(fileName)} disabled={isLoading}>
                            추출하기
                          </Button>
                        </Stack.Item>
                        {/*<Stack.Item>*/}
                        {/*  <Button*/}
                        {/*    onClick={() => handleClickUpload(`webflow/formula_set/${fileName}`)}*/}
                        {/*    disabled={isLoading}*/}
                        {/*  >*/}
                        {/*    이미지 url 복사*/}
                        {/*  </Button>*/}
                        {/*</Stack.Item>*/}
                      </>
                    );
                  }}
                </PreloadedQueryRenderer>
              </Suspense>
            ) : null}
            {isLoading ? (
              <Stack.Item>
                <Spinner size={'small'} />
              </Stack.Item>
            ) : null}
          </Stack>
          <ItemList
            items={urls}
            renderItem={(url) => (
              <CopyableText key={url} fontSize={0}>
                {url}
              </CopyableText>
            )}
          />
          {queryReference ? (
            <Suspense>
              <PreloadedQueryRenderer query={formulaSetsForFormulaSetImageExportDialog} queryReference={queryReference}>
                {({ formulaSets }) => {
                  const formulaSet = formulaSets.edges[0]?.node;

                  if (!formulaSet)
                    return (
                      <View sx={{ padding: 5 }}>
                        <EmptyState title={'검색된 개념집이 없어요'} />
                      </View>
                    );

                  return (
                    <ItemList
                      items={formulaSet.formulas.edges.map(({ node: { id, title, text } }, index) => ({
                        id: id,
                        title: `#${index + 1}`,
                        description: (
                          <>
                            <Text fontSize={1} fontWeight={'bold'}>
                              <Katex>{title}</Katex>
                            </Text>
                            <View sx={{ marginTop: 2 }}>
                              <Katex>{text}</Katex>
                            </View>
                          </>
                        ),
                      }))}
                      renderItem={({ title, description }) => (
                        <>
                          <Text fontWeight={'bold'} fontSize={1}>
                            {title}
                          </Text>
                          <View
                            sx={{
                              width: 'fit-content',
                              maxWidth: '100%',
                              borderStyle: 'solid',
                              borderWidth: 1,
                              borderColor: 'border.default',
                              marginTop: 3,
                              overflowX: 'scroll',
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            <View
                              className={CONTAINER_CLASSNAME}
                              sx={{ width: width || '100%', lineHeight: 0 }}
                              data-color-mode={'light'}
                              data-light-theme={'light'}
                            >
                              {description}
                            </View>
                          </View>
                        </>
                      )}
                      renderItemWrapper={(children, { id }) => (
                        <View key={id} sx={{ marginTop: 5 }}>
                          {children}
                        </View>
                      )}
                    />
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          ) : (
            <View sx={{ padding: 5 }}>
              <EmptyState title={'개념집을 검색해 주세요'} />
            </View>
          )}
        </>
      </Dialog.Body>
    </Dialog>
  );
};

export default FormulaSetImageExportDialog;
