/**
 * @generated SignedSource<<265f1e36412315a71ce7b3de6f590c07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery$variables = {
  id: string;
};
export type MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery$data = {
  readonly salesRecord: {
    readonly consultationHistories: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
    }>;
    readonly id: string;
  };
};
export type MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery = {
  response: MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery$data;
  variables: MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "MATSalesRecord",
    "kind": "LinkedField",
    "name": "salesRecord",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MATConsultationHistory",
        "kind": "LinkedField",
        "name": "consultationHistories",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d479b32314a88a1af1ab6fb8410cdb89",
    "id": null,
    "metadata": {},
    "name": "MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery",
    "operationKind": "query",
    "text": "query MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery(\n  $id: ID!\n) {\n  salesRecord(id: $id) {\n    id\n    consultationHistories {\n      id\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4c918c23c6bf390e18335f807397d21";

export default node;
