import { MouseEventHandler, useState } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';

import { summarizeConsultationHistories } from '../../../ai/matConsultationHistory';
import { MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery } from '../../../relay/__generated__/MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery.graphql';
import { isNullable } from '../../../utils/is';
import Button, { ButtonProps } from '../../core/Button';
import Text from '../../core/Text';
import View from '../../core/View';

const salesRecordForMATSalesRecordConsultationHistoriesSummarizeButton = graphql`
  query MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery($id: ID!) {
    salesRecord(id: $id) {
      id
      consultationHistories {
        id
        description
      }
    }
  }
`;

type Props = { matSalesRecordId: string } & ButtonProps;

const MATSalesRecordConsultationHistoriesSummarizeButton = ({ matSalesRecordId, ...props }: Props) => {
  const environment = useRelayEnvironment();

  const [data, setData] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const handleClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    props.onClick?.(e);

    setIsLoading(true);
    const queryResult = await fetchQuery<MATSalesRecordConsultationHistoriesSummarizeButton_salesRecordQuery>(
      environment,
      salesRecordForMATSalesRecordConsultationHistoriesSummarizeButton,
      { id: matSalesRecordId },
      { fetchPolicy: 'store-or-network' },
    ).toPromise();
    if (!queryResult) {
      setError('데이터를 불러오는데 실패했어요');
      setIsLoading(false);
      return;
    }

    const result = await summarizeConsultationHistories(
      [...queryResult.salesRecord.consultationHistories.map(({ description }) => description)].reverse(),
    );
    if (result) {
      setData(result);
    } else {
      setError('요약에 실패했어요');
    }

    setIsLoading(false);
  };

  return (
    <>
      <Button {...props} disabled={isLoading || !isNullable(data) || props.disabled} onClick={handleClick}>
        {isLoading ? '요약 중...' : data ? '요약 성공' : '요약하기'}
      </Button>
      {data || error ? (
        <View mt={3}>
          <Text whiteSpace={'pre-wrap'} fontSize={0}>
            {data || error}
          </Text>
        </View>
      ) : null}
    </>
  );
};

export default MATSalesRecordConsultationHistoriesSummarizeButton;
