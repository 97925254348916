import { CheckIcon } from '@primer/octicons-react';
import { formatISO } from 'date-fns';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import { useMeContext } from '../../../contexts/MeContext';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery,
  MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$variables,
} from '../../../relay/__generated__/MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery.graphql';
import {
  MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery,
  MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery$variables,
} from '../../../relay/__generated__/MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery.graphql';
import { MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation } from '../../../relay/__generated__/MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import HorizontalDivider from '../../core/HorizontalDivider';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import MATSalesRecordConsultationHistoriesSummarizeButton from '../../matSalesRecord/MATSalesRecordConsultationHistoriesSummarizeButton';
import MATConsultationHistoryDescriptionList from '../MATConsultationHistoryDescriptionList';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation>,
    'onSubmit' | 'config' | 'connections'
  > &
  Pick<
    MutationFormikProps<MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation>['initialValues'],
    'salesRecord'
  >;

const MATConsultationHistoryCreateDialog = ({ salesRecord, onSubmit, config, connections, ...props }: Props) => {
  const { id } = useMeContext();

  return (
    <Dialog wide fillHeight {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>상담 기록 추가하기</Stack.Item>
          <Stack.Item>
            <Suspense fallback={<Spinner size={'small'} />}>
              {salesRecord ? <SalesRecordTitleLabel id={salesRecord} /> : null}
            </Suspense>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation>
        mutation={graphql`
          mutation MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation(
            $input: MATConsultationHistoryCreateInput!
          ) {
            consultationHistoryCreate(input: $input) {
              id
              salesRecord {
                id
                consultationHistories {
                  id
                  created
                  createdBy {
                    id
                    name
                  }
                  nextContactDate
                  description
                }
              }
            }
          }
        `}
        initialValues={{ salesRecord, createdBy: id, description: '', nextContactDate: null }}
        valuesTransformer={(values) => ({
          ...values,
          nextContactDate: values.nextContactDate
            ? formatISO(new Date(values.nextContactDate), { representation: 'date' })
            : null,
        })}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }, { isMutationInFlight, scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
              <Grid.Unit size={7 / 12}>
                <View p={5} overflow={'auto'} height={'100%'} ref={scrollContainerRef}>
                  <FormLayout>
                    <NonFieldError ref={nonFieldErrorRef} />
                    <DateField label={'다음 컨택일'} placeholder={'날짜 선택'} name={'nextContactDate'} />
                    <TextareaField
                      label={'상담 기록'}
                      placeholder={'상담 기록 입력'}
                      name={'description'}
                      autoHeight
                      rows={24}
                    />
                  </FormLayout>
                </View>
              </Grid.Unit>
              <Grid.Unit size={5 / 12}>
                <View p={5} overflow={'auto'} height={'100%'} backgroundColor={'canvas.inset'}>
                  {salesRecord ? (
                    <View mb={3}>
                      <MATSalesRecordConsultationHistoriesSummarizeButton
                        matSalesRecordId={salesRecord}
                        size={'small'}
                        variant={'default'}
                        sx={{ width: '100%' }}
                      >
                        요약하기
                      </MATSalesRecordConsultationHistoriesSummarizeButton>
                    </View>
                  ) : null}

                  <Suspense fallback={<Spinner />}>
                    {salesRecord ? <SalesRecordConsultationHistoryItemList id={salesRecord} /> : null}
                  </Suspense>
                </View>
              </Grid.Unit>
            </Grid>
            <Dialog.Footer>
              <Button
                variant={'primary'}
                leadingIcon={CheckIcon}
                size={'large'}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isMutationInFlight}
              >
                추가하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

const SalesRecordTitleLabel = ({
  id,
}: MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery$variables) => {
  const [{ salesRecord }] =
    useLazyLoadQuery<MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery>(
      graphql`
        query MATConsultationHistoryCreateDialog_SalesRecordTitleLabel_oraganizationQuery($id: ID!) {
          salesRecord(id: $id) {
            id
            title
          }
        }
      `,
      { id },
    );

  return (
    <Label size={'small'} variant={'accent'}>
      {salesRecord.title}
    </Label>
  );
};

const SalesRecordConsultationHistoryItemList = ({
  id,
}: MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$variables) => {
  const [{ salesRecord }] =
    useLazyLoadQuery<MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery>(
      graphql`
        query MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery($id: ID!) {
          salesRecord(id: $id) {
            id
            consultationHistories {
              id
              ...MATConsultationHistoryDescriptionList_matConsultationHistory
              description
            }
          }
        }
      `,
      { id },
    );

  return (
    <ItemList
      items={salesRecord.consultationHistories.filter((consultationHistory) => !!consultationHistory)}
      renderItem={(consultationHistory) => (
        <Card backgroundColor={'fg.default'}>
          <View p={3}>
            <MATConsultationHistoryDescriptionList
              matConsultationHistory={consultationHistory}
              titleUnitSize={1 / 3}
              descriptionUnitSize={2 / 3}
              renderTitle={(title) => (
                <Text fontSize={0} color={'fg.subtle'}>
                  {title}
                </Text>
              )}
              renderDescription={(description) => (
                <Text fontSize={0} color={'fg.default'}>
                  {description}
                </Text>
              )}
              renderItemWrapper={(children, _, i) => (
                <View key={i} sx={{ marginTop: i > 0 ? 1 : 0 }}>
                  {children}
                </View>
              )}
            />
          </View>
          <HorizontalDivider />
          <View p={3}>
            <Text whiteSpace={'pre-wrap'} fontSize={0}>
              {consultationHistory.description}
            </Text>
          </View>
        </Card>
      )}
      renderItemWrapper={(children, { id }, i) => (
        <View key={id} mt={i > 0 ? 3 : 0}>
          <Text fontSize={1} fontWeight={'bold'}>
            {salesRecord.consultationHistories.length - i}차
          </Text>
          <View mt={2}>{children}</View>
        </View>
      )}
      emptyState={<EmptyState title={'상담 기록이 없어요'} />}
    />
  );
};

export default MATConsultationHistoryCreateDialog;
