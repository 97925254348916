import { useField } from 'formik';
import { RefObject, Suspense } from 'react';
import { graphql } from 'react-relay';

import useSearchOverlayQueryLoader from '../../../hooks/useSearchOverlayQueryLoader';
import {
  MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery,
  MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery$data,
} from '../../../relay/__generated__/MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery.graphql';
import { isNullable } from '../../../utils/is';
import ErrorBoundary from '../../core/ErrorBoundary';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayField, { SearchOverlayFieldProps } from '../../core/SearchOverlayField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const acquisitionChannelUtmSourcesForMATAcquisitionChannelUtmSourcesSearchOverlayField = graphql`
  query MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery(
    $filters: MATAcquisitionChannelUtmSourceFilter
  ) {
    acquisitionChannelUtmSources(filters: $filters)
  }
`;

type Props = {} & Omit<
  SearchOverlayFieldProps<{
    id: MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery$data['acquisitionChannelUtmSources'][number];
  }>,
  'renderItemList'
>;

const MATAcquisitionChannelUtmSourcesSearchOverlayField = ({ name = '', value: propValue, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [searchOverlayFieldProps, queryReference] =
    useSearchOverlayQueryLoader<MATAcquisitionChannelUtmSourcesSearchOverlayField_acquisitionChannelUtmSourcesQuery>(
      acquisitionChannelUtmSourcesForMATAcquisitionChannelUtmSourcesSearchOverlayField,
    );

  return (
    <SearchOverlayField
      value={value}
      name={name}
      renderItemList={({ controllingElementRef, containerRef, handleSelect }, itemProps) =>
        queryReference ? (
          <ErrorBoundary>
            <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
              <PreloadedQueryRenderer
                query={acquisitionChannelUtmSourcesForMATAcquisitionChannelUtmSourcesSearchOverlayField}
                queryReference={queryReference}
              >
                {({ acquisitionChannelUtmSources }) => {
                  const items = acquisitionChannelUtmSources.map((acquisitionChannelUtmSource) => ({
                    id: acquisitionChannelUtmSource,
                    title: acquisitionChannelUtmSource,
                  }));
                  return (
                    <ScrollContainer ref={containerRef as RefObject<HTMLDivElement>}>
                      <ItemList
                        items={
                          controllingElementRef.current?.value
                            ? [
                                {
                                  id: controllingElementRef.current?.value,
                                  title: `"${controllingElementRef.current?.value}"으로 입력`,
                                },
                                ...items,
                              ]
                            : items
                        }
                        renderItem={({ title }) => <Text sx={{ fontSize: 1 }}>{title}</Text>}
                        renderItemWrapper={(children, { id }, i) => (
                          <View
                            {...itemProps}
                            key={`${id}-${i}`}
                            onClick={(e) => handleSelect(e, { id })}
                            onKeyDown={(e) => handleSelect(e, { id })}
                          >
                            {children}
                          </View>
                        )}
                      />
                    </ScrollContainer>
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          </ErrorBoundary>
        ) : null
      }
      {...searchOverlayFieldProps}
      {...props}
    />
  );
};

export default MATAcquisitionChannelUtmSourcesSearchOverlayField;
