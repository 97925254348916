import { Textarea as PrimerTextarea, TextareaProps as PrimerTextareaProps } from '@primer/react';
import { forcePixelValue } from '@teamturing/utils';
import { forwardRef, Ref } from 'react';

import { useDialogContext } from '../../../contexts/DialogContext';

type Props = {
  autoHeight?: boolean;
} & PrimerTextareaProps;

const Textarea = ({ onKeyDown, autoHeight = false, ...props }: Props, ref: Ref<HTMLTextAreaElement>) => {
  const dialogContext = useDialogContext();
  const handleKeyDown: PrimerTextareaProps['onKeyDown'] = (event) => {
    if (!!dialogContext && event.key === 'Tab') event.stopPropagation();
    onKeyDown?.(event);
  };

  return (
    <PrimerTextarea
      ref={ref}
      resize={'vertical'}
      onKeyDown={handleKeyDown}
      {...props}
      onChange={(e) => {
        if (autoHeight) {
          e.target.style.height = 'auto';
          e.target.style.height = forcePixelValue(e.target.scrollHeight);
        }

        props.onChange?.(e);
      }}
    />
  );
};

export default forwardRef(Textarea);
export type { Props as TextareaProps };
