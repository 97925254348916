/**
 * @generated SignedSource<<a233d362b4448bcaf680df8b15fed874>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationStudentSizeEnum = "fifty_one_to_two_hundred" | "over_two_hundred" | "ten_to_fifty" | "under_ten" | "%future added value";
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
export type MATSalesRecordPriorityEnum = "high" | "highest" | "low" | "lowest" | "medium" | "%future added value";
export type MATSalesRecordRegionEnum = "b01" | "b02" | "b03" | "b04" | "b05" | "b06" | "b07" | "b08" | "b09" | "b10" | "b11" | "b12" | "b13" | "b14" | "b15" | "b16" | "cb01" | "cb02" | "cb03" | "cb04" | "cb05" | "cb06" | "cb07" | "cb08" | "cb09" | "cb10" | "cb11" | "cn01" | "cn02" | "cn03" | "cn04" | "cn05" | "cn06" | "cn07" | "cn08" | "cn09" | "cn10" | "cn11" | "cn12" | "cn13" | "cn14" | "cn15" | "d01" | "d02" | "d03" | "d04" | "d05" | "d06" | "d07" | "d08" | "dj01" | "dj02" | "dj03" | "dj04" | "dj05" | "g01" | "g02" | "g03" | "g04" | "g05" | "gb01" | "gb02" | "gb03" | "gb04" | "gb05" | "gb06" | "gb07" | "gb08" | "gb09" | "gb10" | "gb11" | "gb12" | "gb13" | "gb14" | "gb15" | "gb16" | "gb17" | "gb18" | "gb19" | "gb20" | "gb21" | "gg01" | "gg02" | "gg03" | "gg04" | "gg05" | "gg06" | "gg07" | "gg08" | "gg09" | "gg10" | "gg11" | "gg12" | "gg13" | "gg14" | "gg15" | "gg16" | "gg17" | "gg18" | "gg19" | "gg20" | "gg21" | "gg22" | "gg23" | "gg24" | "gg25" | "gg26" | "gg27" | "gg28" | "gg29" | "gg30" | "gg31" | "gn01" | "gn02" | "gn03" | "gn04" | "gn05" | "gn06" | "gn07" | "gn08" | "gn09" | "gn10" | "gn11" | "gn12" | "gn13" | "gn14" | "gn15" | "gn16" | "gn17" | "gn18" | "i01" | "i02" | "i03" | "i04" | "i05" | "i06" | "i07" | "i08" | "i09" | "i10" | "jb01" | "jb02" | "jb03" | "jb04" | "jb05" | "jb06" | "jb07" | "jb08" | "jb09" | "jb10" | "jb11" | "jb12" | "jb13" | "jb14" | "jj01" | "jj02" | "jn01" | "jn02" | "jn03" | "jn04" | "jn05" | "jn06" | "jn07" | "jn08" | "jn09" | "jn10" | "jn11" | "jn12" | "jn13" | "jn14" | "jn15" | "jn16" | "jn17" | "jn18" | "jn19" | "kw01" | "kw02" | "kw03" | "kw04" | "kw05" | "kw06" | "kw07" | "kw08" | "kw09" | "kw10" | "kw11" | "kw12" | "kw13" | "kw14" | "kw15" | "kw16" | "kw17" | "s01" | "s02" | "s03" | "s04" | "s05" | "s06" | "s07" | "s08" | "s09" | "s10" | "s11" | "s12" | "s13" | "s14" | "s15" | "s16" | "s17" | "s18" | "s19" | "s20" | "s21" | "s22" | "s23" | "s24" | "s25" | "sj01" | "u01" | "u02" | "u03" | "u04" | "u05" | "%future added value";
export type MATSalesRecordStatusEnum = "absent" | "contract_planned" | "contract_succeed" | "exclude" | "feature_show" | "follow_up_required" | "ongoing" | "pending" | "%future added value";
export type MATSalesRecordUpdateInput = {
  description?: string | null;
  email?: string | null;
  id: string;
  name?: string | null;
  organization?: string | null;
  organizationStudentSize?: MATOrganizationStudentSizeEnum | null;
  organizationType?: MATOrganizationTypeEnum | null;
  phoneNumber?: any | null;
  priority?: MATSalesRecordPriorityEnum | null;
  region?: MATSalesRecordRegionEnum | null;
  status?: MATSalesRecordStatusEnum | null;
  title?: string | null;
};
export type MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation$variables = {
  input: MATSalesRecordUpdateInput;
};
export type MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation$data = {
  readonly salesRecordUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordStatusUpdateActionList_matSalesRecord">;
  };
};
export type MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation = {
  response: MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation$data;
  variables: MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATSalesRecord",
        "kind": "LinkedField",
        "name": "salesRecordUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATSalesRecordStatusUpdateActionList_matSalesRecord"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATSalesRecord",
        "kind": "LinkedField",
        "name": "salesRecordUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6e963ecaa5fd6a86994c22a557c431d",
    "id": null,
    "metadata": {},
    "name": "MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation(\n  $input: MATSalesRecordUpdateInput!\n) {\n  salesRecordUpdate(input: $input) {\n    id\n    ...MATSalesRecordStatusUpdateActionList_matSalesRecord\n  }\n}\n\nfragment MATSalesRecordStatusUpdateActionList_matSalesRecord on MATSalesRecord {\n  id\n  status\n}\n"
  }
};
})();

(node as any).hash = "2eefa8b2fed0efcff849b2a2c772f738";

export default node;
