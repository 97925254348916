import { CommentDiscussionIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense, useEffect, useState } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import Checkbox from '../../components/core/Checkbox';
import RowWrapper from '../../components/core/DataTable/RowWrapper';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairCheckboxGroupField from '../../components/core/EnumPairCheckboxGroupField';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import MATAcquisitionChannelUtmSourcesSearchOverlayField from '../../components/matAcquisitionChannel/MATAcquisitionChannelUtmSourcesSearchOverlayField';
import MATSalesRecordConnectionDataTable from '../../components/matSalesRecord/MATSalesRecordConnectionDataTable';
import MATSalesRecordWindowPaginator from '../../components/matSalesRecord/MATSalesRecordWindowPaginator';
import MATSalesRecordsAssignDialog from '../../components/matSalesRecord/MATSalesRecordsAssignDialog';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import {
  MATOrganizationTypeEnum,
  MATOrganizationStudentSizeEnum,
  MATSalesRecordPriorityEnum,
  MATSalesRecordRegionEnum,
  MATSalesRecordStatusEnum,
  salesRecord_salesRecordsQuery,
} from '../../relay/__generated__/salesRecord_salesRecordsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

const salesRecordsForSalesRecord = graphql`
  query salesRecord_salesRecordsQuery(
    $filters: MATSalesRecordFilter
    $order: MATSalesRecordOrder
    $page: Int
    $pageSize: Int
  ) {
    ...MATSalesRecordWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const SalesRecord: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },

    assignedTo_In: { type: 'string', multiple: true },
    organizationType_In: { type: 'string', multiple: true },
    status_In: { type: 'string', multiple: true },
    priority_In: { type: 'string', multiple: true },
    organizationStudentSize_In: { type: 'string', multiple: true },
    region_In: { type: 'string', multiple: true },
    latestAcquisitionChannel_UtmSource_Contains: { type: 'string' },
  });
  const {
    search,
    order,
    page,
    assignedTo_In,
    organizationType_In,
    status_In,
    priority_In,
    organizationStudentSize_In,
    region_In,
    latestAcquisitionChannel_UtmSource_Contains,
  } = initialValues;

  const { pageSize } = usePaginationContext();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  useEffect(() => {
    return () => setSelectedItems([]);
  }, [JSON.stringify(initialValues)]);

  return (
    <View>
      <Head siteTitle={'영업 관리'} />
      <QueryFormik<salesRecord_salesRecordsQuery>
        query={salesRecordsForSalesRecord}
        staticVariables={{ pageSize }}
        initialValues={{
          filters: {
            search,
            assignedTo_In,
            organizationType_In: organizationType_In as MATOrganizationTypeEnum[],
            status_In: status_In as MATSalesRecordStatusEnum[],
            priority_In: priority_In as MATSalesRecordPriorityEnum[],
            organizationStudentSize_In: organizationStudentSize_In as MATOrganizationStudentSizeEnum[],
            region_In: region_In as MATSalesRecordRegionEnum[],
            latestAcquisitionChannel_UtmSource_Contains,
          },
          order: order || '-latestAcquisitionChannelCreated',
          page,
        }}
        options={{ fetchPolicy: 'store-and-network' }}
        onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        enableReinitialize
      >
        {({ values: { filters }, setFieldValue, submitForm, resetForm }, queryReference) => {
          const handleReset = () => {
            resetForm({ values: { order } });
            setParsedUrlQuery({ order }, { scroll: false });
          };

          const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
            newValue,
          ) => {
            setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
          };
          return (
            <View>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text as={'h1'}>영업 관리</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogButton
                    disabled={selectedItems.length === 0}
                    size={'large'}
                    variant={'primary'}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <MATSalesRecordsAssignDialog
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        salesRecords={selectedItems}
                        config={{
                          onCompleted: () => {
                            toast('배정 성공했어요', 'success');
                            setSelectedItems([]);
                            closeDialog();
                          },
                          onError: () => {
                            toast('배정 실패했어요', 'error');
                          },
                        }}
                      />
                    )}
                  >
                    배정하기
                  </DialogButton>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={'min'}>
                  <QueryFormik.FilterSearchTextField
                    typename={'MATSalesRecordFilter'}
                    label={'Search'}
                    labelConfig={{ visuallyHidden: true }}
                    name={'filters.search'}
                    autoComplete={'off'}
                    leadingVisual={SearchIcon}
                    size={'large'}
                    debounce
                    onChange={(e) => {
                      setFieldValue('filters.search', e.target.value);
                      setTimeout(() => submitForm(), 0);
                    }}
                  />
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <View sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                          초기화
                        </Button>
                      </Stack.Item>
                      <Stack.Item>
                        <QueryFormik.OrderActionMenuButton
                          typename={'MATSalesRecordOrder'}
                          orders={[
                            'latestAcquisitionChannelCreated',
                            'latestConsultationHistoryCreated',
                            'latestConsultationHistoryNextContactDate',
                          ]}
                          onChange={handleChangeOrderActionMenu}
                        />
                      </Stack.Item>
                    </Stack>
                  </View>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 5 }}>
                <QueryFormik.FilterGrid
                  onChange={(filters) => {
                    setParsedUrlQuery({ ...filters, order }, { scroll: false });
                  }}
                >
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'담당자'}>
                    <UserPaginatorSearchOverlayTokenField
                      label={'담당자'}
                      name={'filters.assignedTo_In'}
                      placeholder={'담당자 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'업체'}>
                    <EnumPairCheckboxGroupField
                      typename={'MATOrganizationTypeEnum'}
                      label={'업체'}
                      name={'filters.organizationType_In'}
                      renderContainer={(children) => (
                        <View sx={{ paddingX: 2 }}>
                          <Grid gapX={2}>{children}</Grid>
                        </View>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={'min'}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'상태'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'MATSalesRecordStatusEnum'}
                      label={'상태'}
                      name={'filters.status_In'}
                      placeholder={'상태 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'우선순위'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'MATSalesRecordPriorityEnum'}
                      label={'우선순위'}
                      name={'filters.priority_In'}
                      placeholder={'우선순위 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'규모'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'MATOrganizationStudentSizeEnum'}
                      label={'규모'}
                      name={'filters.organizationStudentSize_In'}
                      placeholder={'규모 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'지역'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'MATSalesRecordRegionEnum'}
                      label={'지역'}
                      name={'filters.region_In'}
                      placeholder={'지역 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'인입 경로'}>
                    <MATAcquisitionChannelUtmSourcesSearchOverlayField
                      label={'인입 경로'}
                      name={'filters.latestAcquisitionChannel_UtmSource_Contains'}
                      placeholder={'인입 경로 검색'}
                    />
                  </QueryFormik.FilterGridUnit>
                </QueryFormik.FilterGrid>
              </View>
              <View sx={{ marginTop: 5 }}>
                <ErrorBoundary key={queryReference?.fetchKey}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<salesRecord_salesRecordsQuery>>
                      {(queryReference) => (
                        <MATSalesRecordWindowPaginator
                          fragmentReference={queryReference}
                          onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                        >
                          {({ salesRecords }, { renderPagination }) => (
                            <>
                              <View>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                  총 {numberWithCommas(salesRecords.totalCount || 0)}개
                                </Text>
                              </View>
                              <View sx={{ marginTop: 3 }}>
                                <MATSalesRecordConnectionDataTable
                                  matSalesRecordConnection={salesRecords}
                                  renderHead={(columns) => (
                                    <View
                                      as={'thead'}
                                      sx={{
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: 'border.default',
                                        backgroundColor: 'canvas.subtle',
                                      }}
                                    >
                                      <View as={'tr'}>
                                        <View as={'th'} sx={{ padding: 2 }}>
                                          <Checkbox
                                            checked={selectedItems.length === salesRecords.edges.length}
                                            onChange={(e) => {
                                              setSelectedItems(
                                                e.target.checked ? salesRecords.edges.map(({ node }) => node.id) : [],
                                              );
                                            }}
                                          />
                                        </View>
                                        {columns.map(({ field, title, width }) => (
                                          <View
                                            key={field}
                                            as={'th'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              textAlign: 'start',
                                              padding: 2,
                                              fontWeight: 'bold',
                                              color: 'fg.muted',
                                            }}
                                          >
                                            {title}
                                          </View>
                                        ))}
                                      </View>
                                    </View>
                                  )}
                                  renderRowWrapper={(children, row) => {
                                    const isSelected = !!selectedItems.find((id) => id === row.id);
                                    return (
                                      <RowWrapper
                                        key={row.id}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          const pathname = `/salesRecord/${row.id}`;
                                          router.push(
                                            { pathname, query: normalizeObject({ ...filters, order }) },
                                            pathname,
                                          );
                                        }}
                                      >
                                        <View
                                          as={'td'}
                                          sx={{
                                            cursor: 'default',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            padding: 2,
                                          }}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Checkbox
                                            checked={isSelected}
                                            onChange={() => {
                                              setSelectedItems(
                                                isSelected
                                                  ? selectedItems.filter((selectedItem) => selectedItem !== row.id)
                                                  : [...selectedItems, row.id],
                                              );
                                            }}
                                          />
                                        </View>
                                        {children}
                                      </RowWrapper>
                                    );
                                  }}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState title={'영업 기록이 없어요'} />
                                    </View>
                                  }
                                />
                              </View>
                              <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                {renderPagination?.()}
                              </View>
                            </>
                          )}
                        </MATSalesRecordWindowPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              </View>
            </View>
          );
        }}
      </QueryFormik>
    </View>
  );
};

SalesRecord.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
SalesRecord.authenticated = true;
SalesRecord.routes = [
  {
    id: 'salesRecord',
    icon: CommentDiscussionIcon,
    pathname: '/salesRecord',
    name: '영업 관리',
    // salesRecord 관련 권한이 따로 없는 관계로 organization_read 권한을 사용합니다.
    permissions: ['organization_read'],
  },
];

export default SalesRecord;
