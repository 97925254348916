import BaseMessage, { BaseMessageProps } from './BaseMessage';
import { KakaoBizMessageContent, KakaoBizMessageContentContainer, KakaoBizMessageImageContainer } from './styles';

type BasicMessageProps = BaseMessageProps & {
  buttons?: BaseMessageProps['buttons'];
};

const BasicMessage: React.FC<BasicMessageProps> = (props) => {
  const { content, image, ...rest } = props;

  return (
    <BaseMessage {...rest} content={content}>
      {image && <KakaoBizMessageImageContainer src={image} alt="" />}
      <KakaoBizMessageContentContainer>
        <KakaoBizMessageContent>{content}</KakaoBizMessageContent>
      </KakaoBizMessageContentContainer>
    </BaseMessage>
  );
};

export default BasicMessage;
