/**
 * @generated SignedSource<<47d574699626d62a4ef19047444e2a7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATSalesRecordPriorityEnum = "high" | "highest" | "low" | "lowest" | "medium" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATSalesRecordPriorityUpdateActionList_matSalesRecord$data = {
  readonly id: string;
  readonly priority: MATSalesRecordPriorityEnum | null;
  readonly " $fragmentType": "MATSalesRecordPriorityUpdateActionList_matSalesRecord";
};
export type MATSalesRecordPriorityUpdateActionList_matSalesRecord$key = {
  readonly " $data"?: MATSalesRecordPriorityUpdateActionList_matSalesRecord$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordPriorityUpdateActionList_matSalesRecord">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATSalesRecordPriorityUpdateActionList_matSalesRecord",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    }
  ],
  "type": "MATSalesRecord",
  "abstractKey": null
};

(node as any).hash = "ed353f4c520a475c6b25ea9920bb8d4a";

export default node;
