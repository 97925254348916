import { graphql, useFragment, useMutation } from 'react-relay';

import withSuspense from '../../../hocs/withSuspense';
import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import {
  MATSalesRecordStatusEnum,
  MATSalesRecordStatusUpdateActionList_matSalesRecord$key,
} from '../../../relay/__generated__/MATSalesRecordStatusUpdateActionList_matSalesRecord.graphql';
import { MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation } from '../../../relay/__generated__/MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation.graphql';
import ActionList, { ActionListProps, ActionListItemProps } from '../../core/ActionList';

const MATSalesRecordStatusUpdateActionList_matSalesRecord = graphql`
  fragment MATSalesRecordStatusUpdateActionList_matSalesRecord on MATSalesRecord {
    id
    status
  }
`;

type Props = {
  matSalesRecord: MATSalesRecordStatusUpdateActionList_matSalesRecord$key;
} & Pick<ActionListItemProps, 'onSelect'> &
  ActionListProps;

const MATSalesRecordStatusUpdateActionList = ({ matSalesRecord, onSelect, ...props }: Props) => {
  const { id, status } = useFragment(MATSalesRecordStatusUpdateActionList_matSalesRecord, matSalesRecord);
  const [mutate] = useMutation<MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation>(graphql`
    mutation MATSalesRecordStatusUpdateActionList_salesRecordUpdateMutation($input: MATSalesRecordUpdateInput!) {
      salesRecordUpdate(input: $input) {
        id
        ...MATSalesRecordStatusUpdateActionList_matSalesRecord
      }
    }
  `);

  const enumPairs = useEnumPairsQuery('MATSalesRecordStatusEnum');

  return (
    <ActionList {...props} selectionVariant={'single'}>
      {enumPairs.map(({ key, value }) => (
        <ActionList.Item
          key={key}
          selected={key === status}
          onSelect={(e) => {
            mutate({ variables: { input: { id, status: key as MATSalesRecordStatusEnum } } });
            onSelect?.(e);
          }}
        >
          {value}
        </ActionList.Item>
      ))}
    </ActionList>
  );
};

export default withSuspense(MATSalesRecordStatusUpdateActionList);
