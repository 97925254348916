import { GraphQLError } from 'graphql/index';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { ProblemGeneratorActionsMutationButtonStack_problemGenerator$key } from '../../../relay/__generated__/ProblemGeneratorActionsMutationButtonStack_problemGenerator.graphql';
import { parseGraphQLError } from '../../../utils/error';
import ButtonGroup from '../../core/ButtonGroup';
import DialogButton from '../../core/DialogButton';
import Stack from '../../core/Stack';
import ProblemGeneratorActivateDialog from '../ProblemGeneratorActivateDialog';
import ProblemGeneratorAssignDialog from '../ProblemGeneratorAssignDialog';
import ProblemGeneratorDeactivateDialog from '../ProblemGeneratorDeactivateDialog';
import ProblemGeneratorPublishButton from '../ProblemGeneratorPublishButton';

const ProblemGeneratorActionsMutationButtonStack_problemGenerator = graphql`
  fragment ProblemGeneratorActionsMutationButtonStack_problemGenerator on ProblemGenerator
  @argumentDefinitions(
    generatedProblemFilters: { type: GeneratedProblemFilter }
    generatedProblemOrder: { type: GeneratedProblemOrder }
  ) {
    id
    actions
    ...ProblemGeneratorActivateDialog_problemGenerator
    ...ProblemGeneratorDeactivateDialog_problemGenerator
    generatedProblems(filters: $generatedProblemFilters, order: $generatedProblemOrder) {
      totalCount
    }
  }
`;

type Props = {
  problemGenerator: ProblemGeneratorActionsMutationButtonStack_problemGenerator$key;
};

const ProblemGeneratorActionsMutationButtonStack = ({ problemGenerator: problemGeneratorReference }: Props) => {
  const problemGenerator = useFragment(
    ProblemGeneratorActionsMutationButtonStack_problemGenerator,
    problemGeneratorReference,
  );
  const { id, actions, generatedProblems } = problemGenerator;

  const { toast } = useToast();

  return (
    <Stack gapX={2}>
      {actions.includes('problem_generator_deactivate') ? (
        <Stack.Item>
          <DialogButton
            variant={'danger'}
            size={'large'}
            renderDialog={({ isOpen, closeDialog }) => (
              <ProblemGeneratorDeactivateDialog
                isOpen={isOpen}
                onDismiss={closeDialog}
                problemGenerator={problemGenerator}
                config={{
                  onCompleted: () => {
                    toast('검수 요청이 성공했어요', 'success');
                    closeDialog();
                  },
                  onError: (error) => {
                    toast(parseGraphQLError(error as GraphQLError)?.[0].message || '검수 요청에 실패했어요', 'error');
                  },
                }}
              />
            )}
          >
            검수 요청하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('problem_generator_activate') ? (
        <Stack.Item>
          <DialogButton
            variant={'primary'}
            size={'large'}
            renderDialog={({ isOpen, closeDialog }) => (
              <ProblemGeneratorActivateDialog
                isOpen={isOpen}
                onDismiss={closeDialog}
                problemGenerator={problemGenerator}
                config={{
                  onCompleted: () => {
                    toast('검수가 완료됐어요', 'success');
                    closeDialog();
                  },
                  onError: (error) => {
                    toast(parseGraphQLError(error as GraphQLError)?.[0].message || '검수 완료에 실패했어요', 'error');
                  },
                }}
              />
            )}
          >
            검수 완료하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('problem_generator_publish') ? (
        <Stack.Item>
          <ButtonGroup>
            <ProblemGeneratorPublishButton
              variant={'primary'}
              size={'large'}
              disabled={!generatedProblems?.totalCount}
              input={{ id, status: 'published' }}
              message={'생성된 문제를 출시할까요?'}
              config={{
                onCompleted: () => {
                  toast('출시 완료됐어요', 'success');
                },
                onError: (error) => {
                  toast(parseGraphQLError(error as GraphQLError)?.[0].message || '출시에 실패했어요', 'error');
                },
              }}
            >
              출시하기
            </ProblemGeneratorPublishButton>
            <ProblemGeneratorPublishButton
              variant={'primary'}
              size={'large'}
              disabled={!generatedProblems?.totalCount}
              input={{ id, status: 'problem_creation' }}
              message={'생성된 문제를 창작 단계로 제출할까요?'}
              config={{
                onCompleted: () => {
                  toast('창작 단계로 제출이 완료됐어요', 'success');
                },
                onError: (error) => {
                  toast(
                    parseGraphQLError(error as GraphQLError)?.[0].message || '창작 단계로 제출에 실패했어요',
                    'error',
                  );
                },
              }}
            >
              창작 단계로 제출하기
            </ProblemGeneratorPublishButton>
          </ButtonGroup>
        </Stack.Item>
      ) : null}
      {actions.includes('problem_generator_assign') ? (
        <Stack.Item>
          <DialogButton
            size={'large'}
            variant={'outline'}
            renderDialog={({ isOpen, closeDialog }) => (
              <ProblemGeneratorAssignDialog
                isOpen={isOpen}
                onDismiss={closeDialog}
                problemGenerators={[id]}
                config={{
                  onCompleted: () => {
                    toast('배정이 완료됐어요', 'success');
                    closeDialog();
                  },
                  onError: (error) => {
                    toast(parseGraphQLError(error as GraphQLError)?.[0].message || '배정에 실패했어요', 'error');
                  },
                }}
              />
            )}
          >
            배정하기
          </DialogButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default ProblemGeneratorActionsMutationButtonStack;
