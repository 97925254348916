import { MortarBoardIcon, SearchIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairCheckboxGroupField from '../../components/core/EnumPairCheckboxGroupField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import MATOrganizationConnectionDataTable from '../../components/matOrganization/MATOrganizationConnectionDataTable';
import MATOrganizationCreateDialog from '../../components/matOrganization/MATOrganizationCreateDialog';
import MATOrganizationWindowPaginator from '../../components/matOrganization/MATOrganizationWindowPaginator';
import { usePaginationContext } from '../../contexts/PaginationContext';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import {
  MATOrganizationTypeEnum,
  organization_organizationsQuery,
} from '../../relay/__generated__/organization_organizationsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import OrganizationId from './[organizationId]';

const organizationsForOrganization = graphql`
  query organization_organizationsQuery(
    $filters: MATOrganizationFilter
    $order: MATOrganizationOrder
    $page: Int
    $pageSize: Int
  ) {
    ...MATOrganizationWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Organization: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },

    type_In: { type: 'string', multiple: true },
    isPaid_In: { type: 'boolean', multiple: true },
  });
  const { search, order, page, type_In, isPaid_In } = initialValues;

  const { pageSize } = usePaginationContext();

  return (
    <View>
      <Head siteTitle={'계약 관리'} />
      <QueryFormik<organization_organizationsQuery>
        query={organizationsForOrganization}
        staticVariables={{ pageSize }}
        initialValues={{
          filters: {
            search,
            type_In: type_In as MATOrganizationTypeEnum[],
            isPaid_In,
          },
          order: order || 'currentInvoiceEndDate',
          page,
        }}
        options={{ fetchPolicy: 'store-and-network' }}
        onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        enableReinitialize
      >
        {({ values: { filters }, setFieldValue, submitForm }, queryReference) => {
          const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
            newValue,
          ) => {
            setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
          };
          return (
            <View>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text as={'h1'}>계약 관리</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogButton
                    size={'large'}
                    variant={'primary'}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <MATOrganizationCreateDialog
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        config={{
                          onCompleted: () => {
                            toast('조직이 생성됐어요', 'success');
                            submitForm();
                            closeDialog();
                          },
                          onError: () => {
                            toast('다시 생성해 주세요', 'error');
                          },
                        }}
                      />
                    )}
                  >
                    생성하기
                  </DialogButton>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={'min'}>
                  <QueryFormik.FilterSearchTextField
                    typename={'MATOrganizationFilter'}
                    label={'Search'}
                    labelConfig={{ visuallyHidden: true }}
                    name={'filters.search'}
                    autoComplete={'off'}
                    leadingVisual={SearchIcon}
                    size={'large'}
                    debounce
                    onChange={(e) => {
                      setFieldValue('filters.search', e.target.value);
                      setTimeout(() => submitForm(), 0);
                    }}
                  />
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <View sx={{ display: 'flex', justifyContent: 'end' }}>
                    <QueryFormik.OrderActionMenuButton
                      typename={'MATOrganizationOrder'}
                      orders={['created', 'currentInvoiceEndDate']}
                      onChange={handleChangeOrderActionMenu}
                    />
                  </View>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 5 }}>
                <QueryFormik.FilterGrid
                  onChange={(filters) => {
                    setParsedUrlQuery({ ...filters, order }, { scroll: false });
                  }}
                >
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'조직 타입'}>
                    <EnumPairCheckboxGroupField
                      typename={'MATOrganizationTypeEnum'}
                      label={'조직 타입'}
                      name={'filters.type_In'}
                      renderContainer={(children) => (
                        <View sx={{ paddingX: 2 }}>
                          <Grid gapX={2}>{children}</Grid>
                        </View>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={'min'}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'결제 여부'}>
                    <CheckboxGroupField
                      name={'filters.isPaid_In'}
                      label={'결제 여부'}
                      options={[
                        { id: 'false', text: '무료' },
                        { id: 'true', text: '유료' },
                      ]}
                      renderContainer={(children) => (
                        <View sx={{ paddingX: 2 }}>
                          <Grid gapX={2}>{children}</Grid>
                        </View>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={'min'}>
                          {children}
                        </Grid.Unit>
                      )}
                      value={filters?.isPaid_In?.map((value) => (value === true ? 'true' : 'false'))}
                      onChange={(selected) =>
                        setFieldValue(
                          'filters.isPaid_In',
                          selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                        )
                      }
                    />
                  </QueryFormik.FilterGridUnit>
                </QueryFormik.FilterGrid>
              </View>
              <View sx={{ marginTop: 5 }}>
                <ErrorBoundary key={queryReference?.fetchKey}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<organization_organizationsQuery>>
                      {(queryReference) => (
                        <MATOrganizationWindowPaginator
                          fragmentReference={queryReference}
                          onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                        >
                          {({ organizations }, { renderPagination }) => (
                            <>
                              <View>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                  총 {numberWithCommas(organizations.totalCount || 0)}개
                                </Text>
                              </View>
                              <View sx={{ marginTop: 3 }}>
                                <MATOrganizationConnectionDataTable
                                  matOrganizationConnection={organizations}
                                  onRowClick={({ id }) => router.push({ pathname: `/organization/${id}` })}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState title={'조직이 없어요'} />
                                    </View>
                                  }
                                />
                              </View>
                              <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                {renderPagination?.()}
                              </View>
                            </>
                          )}
                        </MATOrganizationWindowPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              </View>
            </View>
          );
        }}
      </QueryFormik>
    </View>
  );
};

Organization.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Organization.authenticated = true;
Organization.routes = [
  {
    id: 'organization',
    icon: MortarBoardIcon,
    pathname: '/organization',
    name: '계약 관리',
    permissions: ['organization_read'],
  },
  ...OrganizationId.routes,
];

export default Organization;
