import { GoogleGenerativeAI } from '@google/generative-ai';

const apiKey = process.env.NEXT_PUBLIC_GEMINI_API_KEY;
const genAI = new GoogleGenerativeAI(apiKey!);

const model = genAI.getGenerativeModel({
  model: 'gemini-2.0-flash',
});

const generationConfig = {
  temperature: 0.75,
  topP: 0.95,
  topK: 40,
  maxOutputTokens: 8192,
  responseMimeType: 'text/plain',
};

export async function summarizeConsultationHistories(histories: string[]) {
  const chatSession = model.startChat({
    generationConfig,
    history: [
      {
        role: 'user',
        parts: [
          {
            text: '고객과의 상담한 기록의 목록을 보여줄게. 이 기록들을 보고 다음 상담을 위한 정보들을 요약해서 알려줘.\n\n어떤 대화를 했었는지 핵심적인 부분만 요약해서 알려줘. 요약된 내용은 10문장 안으로 간결하게 정리해줘.\n\n보여준 기록 내에 있는 정보들만 이용해서 요약해야 해. 언제 연락하면 좋은지, 언제 연락했었는지와 같은 날짜 또는 시간에 대한 내용은 말해주지마.\n\n자세한 내용을 알려주자면, "수학대왕 CLASS"라고 하는 수학 교육을 위한 학생 관리용 서비스를 영업하기 위해 상담을 하고 있어. 보통 학원, 학교의 선생님들과 상담을 진행해. 선생님들이 기존에 사용하고 있는 다른 서비스들이 있는 경우가 종종 있어. 다른 서비스라 하면 "매쓰플랫", "마타수학"같은 서비스들이야.\n',
          },
        ],
      },
      {
        role: 'model',
        parts: [
          {
            text: '알겠습니다. 제공해주시는 상담 기록 목록을 확인한 후, 10문장 이내로 핵심 내용을 요약하여 다음 상담을 위한 정보를 정리해 드리겠습니다. 기존 서비스 사용 여부, "수학대왕 CLASS" 서비스에 대한 관심사 등을 파악하여 효과적인 상담 준비를 돕겠습니다.\n',
          },
        ],
      },
    ],
  });

  const result = await chatSession.sendMessage(histories);

  return result.response.text();
}
