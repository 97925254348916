import { graphql } from 'react-relay';

import { ProblemGeneratorPublishButton_problemGeneratorPublishMutation } from '../../../relay/__generated__/ProblemGeneratorPublishButton_problemGeneratorPublishMutation.graphql';
import MutationConfirmButton, { MutationConfirmButtonProps } from '../../core/MutationConfirmButton';

type Props = {} & Omit<
  MutationConfirmButtonProps<ProblemGeneratorPublishButton_problemGeneratorPublishMutation>,
  'mutation'
>;

const ProblemGeneratorPublishButton = ({ ...props }: Props) => {
  return (
    <MutationConfirmButton
      mutation={graphql`
        mutation ProblemGeneratorPublishButton_problemGeneratorPublishMutation($input: ProblemGeneratorPublishInput!) {
          problemGeneratorPublish(input: $input) {
            id
            generatedProblems {
              edges {
                node {
                  id
                  task {
                    id
                    sequence
                  }
                }
              }
            }
            ...ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator
          }
        }
      `}
      message={'생성된 문제를 출시할까요?'}
      {...props}
    />
  );
};

export default ProblemGeneratorPublishButton;
