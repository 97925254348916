export const kakaoTheme = {
  colors: {
    custom: {
      blue: '#aac1d2',
    },
    kakao: {
      yellow: '#fde60a',
      text: '#222222',
      secondary: '#666666',
      heart: '#FF748C',
    },
    background: '#ffffff',
    foreground: '#0c0a09',
    border: '#e2e8f0',
    input: '#e2e8f0',
    ring: '#0c0a09',
    primary: {
      DEFAULT: '#0f172a',
      foreground: '#f8fafc',
    },
    secondary: {
      DEFAULT: '#f1f5f9',
      foreground: '#0f172a',
    },
    destructive: {
      DEFAULT: '#ef4444',
      foreground: '#f8fafc',
    },
    muted: {
      DEFAULT: '#f1f5f9',
      foreground: '#64748b',
    },
    accent: {
      DEFAULT: '#f1f5f9',
      foreground: '#0f172a',
    },
    popover: {
      DEFAULT: '#ffffff',
      foreground: '#0c0a09',
    },
    card: {
      DEFAULT: '#ffffff',
      foreground: '#0c0a09',
    },
    gray: {
      100: '#f7fafc',
      200: '#edf2f7',
      300: '#e2e8f0',
      400: '#cbd5e0',
      500: '#a0aec0',
      600: '#718096',
      700: '#4a5568',
      800: '#2d3748',
      900: '#1a202c',
    },
  },
  borderRadius: {
    'lg': '0.5rem',
    'md': '0.375rem',
    'sm': '0.25rem',
    'xl': '0.75rem',
    '2xl': '1rem',
  },
  spacing: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    16: '4rem',
  },
  fontSize: {
    'xs': '0.75rem',
    'sm': '0.875rem',
    'base': '1rem',
    'lg': '1.125rem',
    'xl': '1.25rem',
    '2xl': '1.5rem',
  },
  fontWeight: {
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  animations: {
    messageAppear: `
      @keyframes messageAppear {
        0% {
          opacity: 0;
          transform: translateY(10px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `,
  },
};

export type KakaoTheme = typeof kakaoTheme;
