import BaseMessage, { BaseMessageProps } from './BaseMessage';
import { KakaoBizMessageContent, KakaoBizMessageContentContainer, KakaoBizMessageImageContainer } from './styles';

type ImageMessageProps = BaseMessageProps & {
  image: string;
  isWide?: BaseMessageProps['isWide'];
  buttons?: BaseMessageProps['buttons'];
};

const ImageMessage: React.FC<ImageMessageProps> = (props) => {
  const { content, image, ...rest } = props;

  return (
    <BaseMessage {...rest} content={content}>
      <KakaoBizMessageImageContainer src={image} alt="" />
      <KakaoBizMessageContentContainer>
        <KakaoBizMessageContent>{content}</KakaoBizMessageContent>
      </KakaoBizMessageContentContainer>
    </BaseMessage>
  );
};

export default ImageMessage;
