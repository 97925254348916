import { ChevronDownIcon, NoteIcon, SignInIcon } from '@primer/octicons-react';
import { first } from '@teamturing/utils';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import {
  MATSalesRecordConnectionDataTable_matSalesRecordConnection$key,
  MATSalesRecordConnectionDataTable_matSalesRecordConnection$data,
} from '../../../relay/__generated__/MATSalesRecordConnectionDataTable_matSalesRecordConnection.graphql';
import { formatMobilePhoneNumber } from '../../../utils/phoneNumber';
import AnchoredOverlay from '../../core/AnchoredOverlay';
import CounterLabel from '../../core/CounterLabel';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import DialogIconButton from '../../core/DialogIconButton';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import Link from '../../core/Link';
import OverlayHandler from '../../core/OverlayHandler';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';
import MATConsultationHistoryCreateDialog from '../../matConsultationHistory/MATConsultationHistoryCreateDialog';
import MATSalesRecordPriorityUpdateActionList from '../MATSalesRecordPriorityUpdateActionList';
import MATSalesRecordStatusLabel from '../MATSalesRecordStatusLabel';
import MATSalesRecordStatusUpdateActionList from '../MATSalesRecordStatusUpdateActionList';

const MATSalesRecordConnectionDataTable_matSalesRecordConnection = graphql`
  fragment MATSalesRecordConnectionDataTable_matSalesRecordConnection on MATSalesRecordConnection {
    edges {
      node {
        id
        ...MATSalesRecordStatusLabel_matSalesRecord
        ...MATSalesRecordStatusUpdateActionList_matSalesRecord
        priority
        ...MATSalesRecordPriorityUpdateActionList_matSalesRecord
        title
        organization {
          id
        }
        name
        phoneNumber
        consultationHistories {
          id
          description
          created
          nextContactDate
        }
        organizationStudentSize
        region
        assignedTo {
          id
          name
        }
        latestAcquisitionChannel {
          id
          utmSource
          created
        }
        description
        organizationType
      }
    }
  }
`;

type Props = {
  matSalesRecordConnection: MATSalesRecordConnectionDataTable_matSalesRecordConnection$key;
} & Omit<
  DataTableProps<MATSalesRecordConnectionDataTable_matSalesRecordConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const MATSalesRecordConnectionDataTable = ({ matSalesRecordConnection, ...props }: Props) => {
  const { edges } = useFragment(MATSalesRecordConnectionDataTable_matSalesRecordConnection, matSalesRecordConnection);
  const { toast } = useToast();

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          width: 120,
          renderValue: (matSalesRecord) => (
            <OverlayHandler>
              {({ isOpen, openOverlay, closeOverlay }) => (
                <AnchoredOverlay
                  open={isOpen}
                  onOpen={openOverlay}
                  onClose={closeOverlay}
                  overlayProps={{ onClick: (e) => e.stopPropagation() }}
                  renderAnchor={(anchorProps) => (
                    <View
                      sx={{ 'height': '100%', 'p': 2, ':hover': { cursor: 'pointer' } }}
                      {...anchorProps}
                      onClick={(e) => {
                        e.stopPropagation();
                        anchorProps.onClick?.(e);
                      }}
                    >
                      <Grid>
                        <Grid.Unit size={'max'}>
                          <MATSalesRecordStatusLabel matSalesRecord={matSalesRecord} size={'small'} />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <StyledOcticon icon={ChevronDownIcon} />
                        </Grid.Unit>
                      </Grid>
                    </View>
                  )}
                >
                  <MATSalesRecordStatusUpdateActionList matSalesRecord={matSalesRecord} onSelect={closeOverlay} />
                </AnchoredOverlay>
              )}
            </OverlayHandler>
          ),
        },
        {
          field: 'priority',
          title: '우선순위',
          width: 92,
          renderValue: (matSalesRecord) => (
            <OverlayHandler>
              {({ isOpen, openOverlay, closeOverlay }) => (
                <AnchoredOverlay
                  open={isOpen}
                  onOpen={openOverlay}
                  onClose={closeOverlay}
                  overlayProps={{ onClick: (e) => e.stopPropagation() }}
                  renderAnchor={(anchorProps) => (
                    <View
                      sx={{ 'p': 2, ':hover': { cursor: 'pointer' } }}
                      {...anchorProps}
                      onClick={(e) => {
                        e.stopPropagation();
                        anchorProps.onClick?.(e);
                      }}
                    >
                      <Grid>
                        <Grid.Unit size={'max'}>
                          <Text
                            fontSize={1}
                            fontWeight={'bold'}
                            color={
                              matSalesRecord.priority === 'highest' || matSalesRecord.priority === 'high'
                                ? 'danger.fg'
                                : matSalesRecord.priority === 'lowest' || matSalesRecord.priority === 'low'
                                ? 'attention.fg'
                                : 'fg.default'
                            }
                          >
                            <Suspense fallback={'...'}>
                              {matSalesRecord.priority ? (
                                <EnumPair typename={'MATSalesRecordPriorityEnum'}>{matSalesRecord.priority}</EnumPair>
                              ) : (
                                '-'
                              )}
                            </Suspense>
                          </Text>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <StyledOcticon icon={ChevronDownIcon} />
                        </Grid.Unit>
                      </Grid>
                    </View>
                  )}
                >
                  <MATSalesRecordPriorityUpdateActionList matSalesRecord={matSalesRecord} onSelect={closeOverlay} />
                </AnchoredOverlay>
              )}
            </OverlayHandler>
          ),
        },
        {
          field: 'title',
          title: '업체명',
          width: 200,
          renderValue: ({ title }) => <View p={2}>{title || '-'}</View>,
        },
        {
          field: 'name',
          title: '이름',
          width: 70,
          renderValue: ({ name }) => <View p={2}>{name}</View>,
        },
        {
          field: 'phoneNumber',
          title: '연락처',
          width: 150,
          renderValue: ({ phoneNumber }) => (
            <View p={2}>{phoneNumber ? formatMobilePhoneNumber(phoneNumber) : '-'}</View>
          ),
        },
        {
          field: 'consultationHistories.first.description',
          title: '상담 기록',
          width: 350,
          renderValue: ({ id, consultationHistories }) => (
            <View p={2} sx={{ maxWidth: 350 }} onClick={(e) => e.stopPropagation()}>
              <Grid gapX={2} wrap={false} sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <View
                    sx={{
                      display: '-webkit-box',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      wordBreak: 'break-word',
                      lineClamp: '25',
                      WebkitLineClamp: '25',
                      // * Typescript 오류를 회피하기 위해 다음과 같은 문법을 사용했습니다.
                      ...{ WebkitBoxOrient: 'vertical' },
                    }}
                  >
                    <Text whiteSpace={'pre-wrap'}>{first([...consultationHistories])?.description || '-'}</Text>
                  </View>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <CounterLabel>{consultationHistories.length}</CounterLabel>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogIconButton
                    size={'small'}
                    icon={NoteIcon}
                    aria-label={'상담 기록 추가하기'}
                    onClick={(e) => e.stopPropagation()}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <MATConsultationHistoryCreateDialog
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        salesRecord={id}
                        config={{
                          onCompleted: () => {
                            toast('상담 기록이 추가됐어요', 'success');
                            closeDialog();
                          },
                          onError: () => {
                            toast('상담 기록이 추가에 실패했어요', 'error');
                          },
                        }}
                      />
                    )}
                  />
                </Grid.Unit>
              </Grid>
            </View>
          ),
        },
        {
          field: 'consultationHistories.first.created',
          title: '최근 상담일시',
          width: 190,
          renderValue: ({ consultationHistories }) => {
            const created = first([...consultationHistories])?.created;
            return <View p={2}>{created ? <DateText>{created}</DateText> : '-'}</View>;
          },
        },
        {
          field: 'consultationHistories.first.nextContactDate',
          title: '다음 컨택일',
          width: 96,
          renderValue: ({ consultationHistories }) => {
            const nextContactDate = first([...consultationHistories])?.nextContactDate;
            return <View p={2}>{nextContactDate ? <DateText format={'P'}>{nextContactDate}</DateText> : '-'}</View>;
          },
        },
        {
          field: 'organizationStudentSize',
          title: '규모',
          width: 80,
          renderValue: ({ organizationStudentSize }) => (
            <View p={2}>
              <Suspense fallback={'...'}>
                {organizationStudentSize ? (
                  <EnumPair typename={'MATOrganizationStudentSizeEnum'}>{organizationStudentSize}</EnumPair>
                ) : (
                  '-'
                )}
              </Suspense>
            </View>
          ),
        },
        {
          field: 'region',
          title: '지역',
          width: 120,
          renderValue: ({ region }) => (
            <View p={2}>
              <Suspense fallback={'...'}>
                {region ? <EnumPair typename={'MATSalesRecordRegionEnum'}>{region}</EnumPair> : '-'}
              </Suspense>
            </View>
          ),
        },
        {
          field: 'organization.id',
          title: '계약 정보',
          width: 70,
          renderValue: ({ organization }) => (
            <View p={2} onClick={(e) => e.stopPropagation()}>
              {organization ? (
                <Link href={{ pathname: '/organization/[organizationId]', query: { organizationId: organization.id } }}>
                  <IconButton size={'small'} icon={SignInIcon} aria-label={'계약 정보 보러가기'} />
                </Link>
              ) : (
                '-'
              )}
            </View>
          ),
          align: 'center',
        },
        {
          field: 'assignedTo',
          title: '담당자',
          width: 95,
          renderValue: ({ assignedTo }) => <View p={2}>{assignedTo ? assignedTo.name : '-'}</View>,
        },
        {
          field: 'latestAcquisitionChannel.utmSource',
          title: '인입 경로',
          width: 95,
          renderValue: ({ latestAcquisitionChannel }) => (
            <View p={2}>
              {latestAcquisitionChannel && latestAcquisitionChannel.utmSource
                ? latestAcquisitionChannel.utmSource
                : '-'}
            </View>
          ),
        },
        {
          field: 'latestAcquisitionChannel.created',
          title: '인입일',
          width: 95,
          renderValue: ({ latestAcquisitionChannel }) => (
            <View p={2}>
              {latestAcquisitionChannel ? <DateText format={'P'}>{latestAcquisitionChannel.created}</DateText> : '-'}
            </View>
          ),
        },
        {
          field: 'description',
          title: '비고',
          width: 240,
          renderValue: ({ description }) => (
            <View p={2}>
              <Text whiteSpace={'pre'}>{description ? description : '-'}</Text>
            </View>
          ),
        },
        {
          field: 'organizationType',
          title: '업체',
          width: 50,
          renderValue: ({ organizationType }) => (
            <View p={2}>
              <Suspense fallback={'...'}>
                {organizationType ? <EnumPair typename={'MATOrganizationTypeEnum'}>{organizationType}</EnumPair> : '-'}
              </Suspense>
            </View>
          ),
        },
      ]}
      renderRow={(row, columns, index) =>
        columns.map(({ field, renderValue, width, align = 'start' }) => (
          <View
            key={field}
            as={'td'}
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'border.default',
              minWidth: width,
              textAlign: align,
            }}
          >
            {renderValue(row, index)}
          </View>
        ))
      }
      {...props}
    />
  );
};

export default MATSalesRecordConnectionDataTable;
