/**
 * @generated SignedSource<<2317d6a17bf221e991f3a7617ba9a34c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskSolutionVideoDeleteInput = {
  id: string;
};
export type TaskId_taskSolutionVideoDeleteMutation$variables = {
  input: TaskSolutionVideoDeleteInput;
};
export type TaskId_taskSolutionVideoDeleteMutation$data = {
  readonly taskSolutionVideoDelete: {
    readonly id: string;
  };
};
export type TaskId_taskSolutionVideoDeleteMutation = {
  response: TaskId_taskSolutionVideoDeleteMutation$data;
  variables: TaskId_taskSolutionVideoDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskId_taskSolutionVideoDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskSolutionVideo",
        "kind": "LinkedField",
        "name": "taskSolutionVideoDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskId_taskSolutionVideoDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskSolutionVideo",
        "kind": "LinkedField",
        "name": "taskSolutionVideoDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8a996f83eabf8396c4e287a95c34dc7",
    "id": null,
    "metadata": {},
    "name": "TaskId_taskSolutionVideoDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TaskId_taskSolutionVideoDeleteMutation(\n  $input: TaskSolutionVideoDeleteInput!\n) {\n  taskSolutionVideoDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "93916e7b4f7dbc72c1a7fad6b80957b6";

export default node;
