import styled from 'styled-components';

import BaseMessage, { BaseMessageProps } from './BaseMessage';
import { KakaoBizMessageContent, KakaoBizMessageContentContainer, KakaoBizMessageImageContainer } from './styles';
import { kakaoTheme } from './theme';

type ItemListMessageProps = BaseMessageProps & {
  header?: string;
  items: Array<{
    title: string;
    description?: string;
    summary?: string;
  }>;
  highlightImage?: string;
  highlightText?: string;
  highlightDescription?: string;
  buttons?: BaseMessageProps['buttons'];
  isWide?: BaseMessageProps['isWide'];
};

const ListContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${kakaoTheme.colors.gray[100]};
`;

const ListHeader = styled.h3`
  font-weight: ${kakaoTheme.fontWeight.bold};
  color: ${kakaoTheme.colors.kakao.text};
`;

const HighlightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const HighlightContent = styled.div`
  flex: 1;
`;

const HighlightText = styled.p`
  font-weight: ${kakaoTheme.fontWeight.medium};
  color: ${kakaoTheme.colors.kakao.text};
  font-size: ${kakaoTheme.fontSize.xl};
  margin-bottom: 0;
`;

const HighlightDescription = styled.p`
  font-size: ${kakaoTheme.fontSize.sm};
  color: ${kakaoTheme.colors.kakao.secondary};
  margin-top: 0.25rem;
`;

const HighlightImage = styled.img`
  width: 108px;
  height: 108px;
  object-fit: cover;
  border-radius: ${kakaoTheme.borderRadius.lg};
  flex-shrink: 0;
`;

const ItemList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-left: 0;
  list-style: none;
`;

const ItemListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const ItemTitle = styled.span`
  color: ${kakaoTheme.colors.kakao.secondary};
  font-weight: ${kakaoTheme.fontWeight.bold};
`;

const ItemDescription = styled.span`
  font-weight: ${kakaoTheme.fontWeight.medium};
  color: ${kakaoTheme.colors.kakao.text};
  white-space: nowrap;
  text-align: right;
`;

const ItemSummary = styled.span`
  text-align: right;
  font-weight: ${kakaoTheme.fontWeight.bold};
  font-size: ${kakaoTheme.fontSize.xl};
  color: ${kakaoTheme.colors.kakao.text};
  white-space: nowrap;
`;

const ItemListMessage: React.FC<ItemListMessageProps> = (props) => {
  const { content, header, items, image, highlightImage, highlightText, highlightDescription, ...rest } = props;

  if (items.length < 2 || items.length > 10) {
    console.error('ItemList must have between 2 and 10 items');
    return null;
  }

  const validateText = (text: string, maxLength: number) => {
    return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
  };

  return (
    <BaseMessage {...rest} content={content}>
      {image && <KakaoBizMessageImageContainer src={image} alt="" />}
      <ListContainer>
        {header && header.length <= 16 && <ListHeader>{header}</ListHeader>}
        {(highlightText || highlightImage) && (
          <HighlightContainer>
            <HighlightContent>
              {highlightText && <HighlightText>{validateText(highlightText, highlightImage ? 21 : 30)}</HighlightText>}
              {highlightDescription && (
                <HighlightDescription>
                  {validateText(highlightDescription, highlightImage ? 13 : 19)}
                </HighlightDescription>
              )}
            </HighlightContent>
            {highlightImage && <HighlightImage src={highlightImage} alt="" />}
          </HighlightContainer>
        )}
        <ItemList>
          {items.map((item, index) => (
            <ItemListItem key={index}>
              <ItemTitle>{validateText(item.title, 6)}</ItemTitle>
              {item.description && <ItemDescription>{validateText(item.description, 23)}</ItemDescription>}
              {item.summary && <ItemSummary>{item.summary}</ItemSummary>}
            </ItemListItem>
          ))}
        </ItemList>
      </ListContainer>
      <KakaoBizMessageContentContainer>
        <KakaoBizMessageContent>{content}</KakaoBizMessageContent>
      </KakaoBizMessageContentContainer>
    </BaseMessage>
  );
};

export default ItemListMessage;
