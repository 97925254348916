import { graphql, useFragment } from 'react-relay';

import {
  MATSalesRecordStatusEnum,
  MATSalesRecordStatusLabel_matSalesRecord$key,
} from '../../../relay/__generated__/MATSalesRecordStatusLabel_matSalesRecord.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const MATSalesRecordStatusLabel_matSalesRecord = graphql`
  fragment MATSalesRecordStatusLabel_matSalesRecord on MATSalesRecord {
    status
  }
`;

type Props = {
  matSalesRecord: MATSalesRecordStatusLabel_matSalesRecord$key;
} & Omit<LabelProps, 'variant'>;

const MATSalesRecordStatusLabel = ({ matSalesRecord, ...props }: Props) => {
  const { status } = useFragment(MATSalesRecordStatusLabel_matSalesRecord, matSalesRecord);

  const labelPropsByStatus: Record<MATSalesRecordStatusEnum, LabelProps> = {
    /*
     * 상담 중
     */
    'ongoing': { variant: 'default' },
    /*
     * 재상담 필요
     */
    'follow_up_required': { variant: 'default' },
    /*
     * 부재
     */
    'absent': { variant: 'default' },
    /*
     * 기능 시연
     */
    'feature_show': { variant: 'attention' },
    /*
     * 보류
     */
    'pending': { variant: 'attention' },
    /*
     * 계약 예정
     */
    'contract_planned': { variant: 'accent' },
    /*
     * 계약 성공
     */
    'contract_succeed': { variant: 'success' },
    /*
     * 제외
     */
    'exclude': { variant: 'default' },

    // 'pending': { variant: 'secondary' },
    // 'completed': { variant: 'success' },
    '%future added value': {},
  };

  return status ? (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'MATSalesRecordStatusEnum'}>{status}</EnumPair>
    </Label>
  ) : (
    <>{'-'}</>
  );
};

export default MATSalesRecordStatusLabel;
