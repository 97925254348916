/**
 * @generated SignedSource<<c28ee5857fc54c23f815f1d66e9053e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATConsultationHistoryCreateInput = {
  createdBy: string;
  description: string;
  nextContactDate?: any | null;
  salesRecord: string;
};
export type MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation$variables = {
  input: MATConsultationHistoryCreateInput;
};
export type MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation$data = {
  readonly consultationHistoryCreate: {
    readonly id: string;
    readonly salesRecord: {
      readonly consultationHistories: ReadonlyArray<{
        readonly created: any;
        readonly createdBy: {
          readonly id: string;
          readonly name: string;
        };
        readonly description: string;
        readonly id: string;
        readonly nextContactDate: any | null;
      }>;
      readonly id: string;
    };
  };
};
export type MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation = {
  response: MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation$data;
  variables: MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATConsultationHistory",
    "kind": "LinkedField",
    "name": "consultationHistoryCreate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MATSalesRecord",
        "kind": "LinkedField",
        "name": "salesRecord",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATConsultationHistory",
            "kind": "LinkedField",
            "name": "consultationHistories",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MATTcmsUser",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nextContactDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cc64844a1f189ed5b528e3240d388876",
    "id": null,
    "metadata": {},
    "name": "MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MATConsultationHistoryCreateDialog_consultationHistoryCreateMutation(\n  $input: MATConsultationHistoryCreateInput!\n) {\n  consultationHistoryCreate(input: $input) {\n    id\n    salesRecord {\n      id\n      consultationHistories {\n        id\n        created\n        createdBy {\n          id\n          name\n        }\n        nextContactDate\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe49ac1500570958e34954e8f0d6dda8";

export default node;
