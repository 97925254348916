import { CopyIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import { commaizeNumber } from '@teamturing/utils';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import AlimTalkExecutionConnectionDataTable from '../../components/alimTalk/AlimTalkExecutionConnectionDataTable';
import AlimTalkPreview from '../../components/alimTalk/AlimTalkPreview';
import AlimTalkTaskCreateDialog from '../../components/alimTalk/AlimTalkTaskCreateDialog';
import AlimTalkTaskDescriptionList from '../../components/alimTalk/AlimTalkTaskDescriptionList';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import SQLEditor from '../../components/core/SQLEditor';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { AlimTalkTaskId_alimTalkExecutionsQuery } from '../../relay/__generated__/AlimTalkTaskId_alimTalkExecutionsQuery.graphql';
import { AlimTalkTaskId_alimTalkPreviewQuery } from '../../relay/__generated__/AlimTalkTaskId_alimTalkPreviewQuery.graphql';
import { AlimTalkTaskId_alimTalkTaskActivateMutation } from '../../relay/__generated__/AlimTalkTaskId_alimTalkTaskActivateMutation.graphql';
import { AlimTalkTaskId_alimTalkTaskDeactivateMutation } from '../../relay/__generated__/AlimTalkTaskId_alimTalkTaskDeactivateMutation.graphql';
import { AlimTalkTaskId_alimTalkTaskQuery } from '../../relay/__generated__/AlimTalkTaskId_alimTalkTaskQuery.graphql';
import { NextPage } from '../_app';

const alimTalkTaskForAlimTalkTaskId = graphql`
  query AlimTalkTaskId_alimTalkTaskQuery($id: ID!) {
    alimTalkTask(id: $id) {
      id
      title
      description
      query
      startAt
      period
      isActive
      template {
        id
        parameterNames
        templateCode
      }
      ...AlimTalkTaskDescriptionList_alimTalkTask
    }
  }
`;

const alimTalkPreviewForAlimTalkTaskId = graphql`
  query AlimTalkTaskId_alimTalkPreviewQuery($id: ID!, $query: String!) {
    alimTalkTemplate(id: $id) {
      id
      templateCode
      parameterNames
      ...AlimTalkPreview_alimTalkTemplate
    }
    alimTalkPreview(templateCodeId: $id, query: $query) {
      targetCount
      ...AlimTalkPreview_alimTalkPreview
    }
  }
`;

const alimTalkExecutionsForAlimTalkTaskId = graphql`
  query AlimTalkTaskId_alimTalkExecutionsQuery($id: ID!) {
    alimTalkExecutions(filters: { taskId_Exact: $id }, order: { executedAt: DESC }, page: 0, pageSize: 10) {
      totalCount
      ...AlimTalkExecutionConnectionDataTable_alimTalkExecutionConnection
    }
  }
`;

type Props = {};

const AlimTalkTaskId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const taskId = router.query.alimTalkTaskId as string;

  const [{ alimTalkTask }] = useLazyLoadQuery<AlimTalkTaskId_alimTalkTaskQuery>(alimTalkTaskForAlimTalkTaskId, {
    id: taskId,
  });

  if (!alimTalkTask) return null;

  const { id, title, description, template, query, startAt, period, isActive } = alimTalkTask;

  const [{ alimTalkTemplate, alimTalkPreview }] = useLazyLoadQuery<AlimTalkTaskId_alimTalkPreviewQuery>(
    alimTalkPreviewForAlimTalkTaskId,
    {
      id: template.id,
      query: query,
    },
  );

  const [{ alimTalkExecutions }] = useLazyLoadQuery<AlimTalkTaskId_alimTalkExecutionsQuery>(
    alimTalkExecutionsForAlimTalkTaskId,
    {
      id: taskId,
    },
  );

  return (
    <View>
      <Head siteTitle={`알림톡 | ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size="max">
            <Text as="h1">{title}</Text>
          </Grid.Unit>

          <Grid.Unit size="min">
            <Stack gapX={2}>
              <Stack.Item>
                <DialogButton
                  renderDialog={({ isOpen, closeDialog }) => (
                    <AlimTalkTaskCreateDialog
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      full
                      templateCodeId={template.id}
                      config={{
                        onCompleted: ({ alimTalkTaskCreate }) => {
                          toast('알림톡이 생성되었어요!', 'success');
                          closeDialog();
                          router.push(`/alimTalkTask/${alimTalkTaskCreate.id}`);
                        },
                        onError: () => {
                          toast('다시 생성해 주세요', 'error');
                        },
                      }}
                      parameterNames={template.parameterNames}
                      initialValues={{
                        title: title,
                        description: description,
                        startAt: startAt,
                        templateCodeId: template.id,
                        period: period,
                        query: query,
                        csvFile: null,
                      }}
                    />
                  )}
                  size="large"
                  leadingIcon={CopyIcon}
                >
                  복제하기
                </DialogButton>
              </Stack.Item>

              <Stack.Item>
                <ActionMenu>
                  <ActionMenu.Anchor>
                    <IconButton icon={KebabHorizontalIcon} variant="plain" aria-label="Open options" />
                  </ActionMenu.Anchor>
                  <ActionMenu.Overlay>
                    <ActionList>
                      {isActive ? (
                        <ActionList.MutationItem<AlimTalkTaskId_alimTalkTaskDeactivateMutation>
                          mutation={graphql`
                            mutation AlimTalkTaskId_alimTalkTaskDeactivateMutation(
                              $input: AlimTalkTaskDeactivateInput!
                            ) {
                              alimTalkTaskDeactivate(input: $input) {
                                id
                              }
                            }
                          `}
                          variant="danger"
                          input={{ id }}
                          config={{
                            onCompleted: () => {
                              toast('알림톡 비활성화 완료!', 'success');
                              router.reload();
                            },
                          }}
                          message="정말 비활성화할까요?"
                        >
                          비활성화하기
                        </ActionList.MutationItem>
                      ) : (
                        <ActionList.MutationItem<AlimTalkTaskId_alimTalkTaskActivateMutation>
                          mutation={graphql`
                            mutation AlimTalkTaskId_alimTalkTaskActivateMutation($input: AlimTalkTaskActivateInput!) {
                              alimTalkTaskActivate(input: $input) {
                                id
                              }
                            }
                          `}
                          variant="default"
                          input={{ id }}
                          config={{
                            onCompleted: () => {
                              toast('알림톡 활성화 완료!', 'success');
                              router.reload();
                            },
                          }}
                          message="활성화할까요?"
                        >
                          활성화하기
                        </ActionList.MutationItem>
                      )}
                    </ActionList>
                  </ActionMenu.Overlay>
                </ActionMenu>
              </Stack.Item>
            </Stack>
          </Grid.Unit>
        </Grid>

        <HorizontalDivider mt={[3, 3, 0]} mb={5} />

        <Grid gapX={5} gapY={3} reverse={[true, true, false]}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View>
              <Text fontSize={1} fontWeight="bold">
                설명
              </Text>
              <Card sx={{ padding: 3, marginTop: 2 }}>
                <Text fontSize={1} whiteSpace="pre-wrap">
                  {description || '-'}
                </Text>
              </Card>
            </View>

            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1} fontWeight="bold">
                알림톡 정보
              </Text>
              <Card sx={{ padding: 4, marginTop: 2 }}>
                <AlimTalkTaskDescriptionList
                  alimTalkTask={alimTalkTask}
                  titleUnitSize={[1, 1, 1 / 6]}
                  descriptionUnitSize={[1, 1, 5 / 6]}
                />
              </Card>
            </View>

            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1}>SQL</Text>
              <SQLEditor readOnly value={query || ''} height={200} sx={{ marginTop: 1 }} />
            </View>

            <View sx={{ marginTop: 8 }}>
              <Stack gapX={2}>
                <Text fontSize={3} fontWeight="bold">
                  알림톡 미리보기
                </Text>
                <Text fontSize={1} color={'fg.muted'} fontWeight={'bold'}>{`약 ${
                  alimTalkPreview?.targetCount ? commaizeNumber(alimTalkPreview?.targetCount) : '-'
                }명`}</Text>
              </Stack>
            </View>
            <View sx={{ marginTop: 3 }}>
              <AlimTalkPreview alimTalkTemplate={alimTalkTemplate} alimTalkPreview={alimTalkPreview} />
            </View>

            <View sx={{ marginTop: 5 }}>
              <Text fontSize={3} fontWeight="bold">
                최근 발송 이력
              </Text>
            </View>

            <View sx={{ marginTop: 3 }}>
              <ErrorBoundary>
                <View sx={{ marginTop: 3 }}>
                  <AlimTalkExecutionConnectionDataTable
                    alimTalkExecutionConnection={alimTalkExecutions}
                    emptyState={
                      <View sx={{ paddingY: 3 }}>
                        <EmptyState title={'발송 이력이 없어요'} description={''} />
                      </View>
                    }
                  />
                </View>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

AlimTalkTaskId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AlimTalkTaskId.authenticated = true;
AlimTalkTaskId.routes = [
  {
    id: 'alimTalkTaskId',
    pathname: '/alimTalkTask/[alimTalkTaskId]',
    name: '알림톡 상세',
    permissions: ['app_push_read'],
  },
];

export default AlimTalkTaskId;
