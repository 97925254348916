/**
 * @generated SignedSource<<63dba0b76a9f10146245f4804f6de496>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$variables = {
  id: string;
};
export type MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$data = {
  readonly salesRecord: {
    readonly consultationHistories: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"MATConsultationHistoryDescriptionList_matConsultationHistory">;
    }>;
    readonly id: string;
  };
};
export type MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery = {
  response: MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$data;
  variables: MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATSalesRecord",
        "kind": "LinkedField",
        "name": "salesRecord",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATConsultationHistory",
            "kind": "LinkedField",
            "name": "consultationHistories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MATConsultationHistoryDescriptionList_matConsultationHistory"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATSalesRecord",
        "kind": "LinkedField",
        "name": "salesRecord",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATConsultationHistory",
            "kind": "LinkedField",
            "name": "consultationHistories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nextContactDate",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4b50bcc3799f2355072e1d7451968ef",
    "id": null,
    "metadata": {},
    "name": "MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery",
    "operationKind": "query",
    "text": "query MATConsultationHistoryCreateDialog_SalesRecordConsultationHistoryItemList_oraganizationQuery(\n  $id: ID!\n) {\n  salesRecord(id: $id) {\n    id\n    consultationHistories {\n      id\n      ...MATConsultationHistoryDescriptionList_matConsultationHistory\n      description\n    }\n  }\n}\n\nfragment MATConsultationHistoryDescriptionList_matConsultationHistory on MATConsultationHistory {\n  created\n  nextContactDate\n}\n"
  }
};
})();

(node as any).hash = "39f7b41f3f9f985cad4d21e47394b67e";

export default node;
