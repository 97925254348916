import { CheckIcon } from '@primer/octicons-react';
import { graphql } from 'react-relay';

import { useMeContext } from '../../../contexts/MeContext';
import { MATSalesRecordsAssignDialog_salesRecordsAssignMutation } from '../../../relay/__generated__/MATSalesRecordsAssignDialog_salesRecordsAssignMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Stack from '../../core/Stack';
import UserAvatarRadioGroupField from '../../user/UserAvatarRadioGroupField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<MATSalesRecordsAssignDialog_salesRecordsAssignMutation>,
    'onSubmit' | 'config' | 'connections'
  > &
  Pick<MutationFormikProps<MATSalesRecordsAssignDialog_salesRecordsAssignMutation>['initialValues'], 'salesRecords'>;

const MATSalesRecordsAssignDialog = ({ salesRecords, onSubmit, config, connections, ...props }: Props) => {
  const { id } = useMeContext();

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>배정하기</Stack.Item>
          <Stack.Item>
            <Label variant={'accent'}>{salesRecords?.length || 0}개</Label>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<MATSalesRecordsAssignDialog_salesRecordsAssignMutation>
        mutation={graphql`
          mutation MATSalesRecordsAssignDialog_salesRecordsAssignMutation($input: MATSalesRecordsAssignInput!) {
            salesRecordsAssign(input: $input) {
              id
              assignedTo {
                id
                name
              }
            }
          }
        `}
        initialValues={{
          assignedBy: id,
          assignedTo: '',
          salesRecords,
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef, isMutationInFlight }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <UserAvatarRadioGroupField label={'담당자'} required name={'assignedTo'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant={'primary'}
                leadingIcon={CheckIcon}
                size={'large'}
                onClick={() => handleSubmit()}
                disabled={isMutationInFlight}
              >
                배정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATSalesRecordsAssignDialog;
