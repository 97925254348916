/**
 * @generated SignedSource<<ece2ddc11ee7203c36b270c75654c790>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationStudentSizeEnum = "fifty_one_to_two_hundred" | "over_two_hundred" | "ten_to_fifty" | "under_ten" | "%future added value";
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
export type MATSalesRecordPriorityEnum = "high" | "highest" | "low" | "lowest" | "medium" | "%future added value";
export type MATSalesRecordRegionEnum = "b01" | "b02" | "b03" | "b04" | "b05" | "b06" | "b07" | "b08" | "b09" | "b10" | "b11" | "b12" | "b13" | "b14" | "b15" | "b16" | "cb01" | "cb02" | "cb03" | "cb04" | "cb05" | "cb06" | "cb07" | "cb08" | "cb09" | "cb10" | "cb11" | "cn01" | "cn02" | "cn03" | "cn04" | "cn05" | "cn06" | "cn07" | "cn08" | "cn09" | "cn10" | "cn11" | "cn12" | "cn13" | "cn14" | "cn15" | "d01" | "d02" | "d03" | "d04" | "d05" | "d06" | "d07" | "d08" | "dj01" | "dj02" | "dj03" | "dj04" | "dj05" | "g01" | "g02" | "g03" | "g04" | "g05" | "gb01" | "gb02" | "gb03" | "gb04" | "gb05" | "gb06" | "gb07" | "gb08" | "gb09" | "gb10" | "gb11" | "gb12" | "gb13" | "gb14" | "gb15" | "gb16" | "gb17" | "gb18" | "gb19" | "gb20" | "gb21" | "gg01" | "gg02" | "gg03" | "gg04" | "gg05" | "gg06" | "gg07" | "gg08" | "gg09" | "gg10" | "gg11" | "gg12" | "gg13" | "gg14" | "gg15" | "gg16" | "gg17" | "gg18" | "gg19" | "gg20" | "gg21" | "gg22" | "gg23" | "gg24" | "gg25" | "gg26" | "gg27" | "gg28" | "gg29" | "gg30" | "gg31" | "gn01" | "gn02" | "gn03" | "gn04" | "gn05" | "gn06" | "gn07" | "gn08" | "gn09" | "gn10" | "gn11" | "gn12" | "gn13" | "gn14" | "gn15" | "gn16" | "gn17" | "gn18" | "i01" | "i02" | "i03" | "i04" | "i05" | "i06" | "i07" | "i08" | "i09" | "i10" | "jb01" | "jb02" | "jb03" | "jb04" | "jb05" | "jb06" | "jb07" | "jb08" | "jb09" | "jb10" | "jb11" | "jb12" | "jb13" | "jb14" | "jj01" | "jj02" | "jn01" | "jn02" | "jn03" | "jn04" | "jn05" | "jn06" | "jn07" | "jn08" | "jn09" | "jn10" | "jn11" | "jn12" | "jn13" | "jn14" | "jn15" | "jn16" | "jn17" | "jn18" | "jn19" | "kw01" | "kw02" | "kw03" | "kw04" | "kw05" | "kw06" | "kw07" | "kw08" | "kw09" | "kw10" | "kw11" | "kw12" | "kw13" | "kw14" | "kw15" | "kw16" | "kw17" | "s01" | "s02" | "s03" | "s04" | "s05" | "s06" | "s07" | "s08" | "s09" | "s10" | "s11" | "s12" | "s13" | "s14" | "s15" | "s16" | "s17" | "s18" | "s19" | "s20" | "s21" | "s22" | "s23" | "s24" | "s25" | "sj01" | "u01" | "u02" | "u03" | "u04" | "u05" | "%future added value";
export type MATSalesRecordStatusEnum = "absent" | "contract_planned" | "contract_succeed" | "exclude" | "feature_show" | "follow_up_required" | "ongoing" | "pending" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type MATSalesRecordFilter = {
  AND?: MATSalesRecordFilter | null;
  NOT?: MATSalesRecordFilter | null;
  OR?: MATSalesRecordFilter | null;
  assignedTo_In?: ReadonlyArray<string> | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  distinct?: boolean | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  latestAcquisitionChannel_UtmSource_Contains?: string | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  organizationStudentSize_In?: ReadonlyArray<MATOrganizationStudentSizeEnum> | null;
  organizationType_In?: ReadonlyArray<MATOrganizationTypeEnum> | null;
  priority_In?: ReadonlyArray<MATSalesRecordPriorityEnum> | null;
  region_In?: ReadonlyArray<MATSalesRecordRegionEnum> | null;
  search?: string | null;
  status_In?: ReadonlyArray<MATSalesRecordStatusEnum> | null;
};
export type MATSalesRecordOrder = {
  created?: Ordering | null;
  latestAcquisitionChannelCreated?: Ordering | null;
  latestConsultationHistoryCreated?: Ordering | null;
  latestConsultationHistoryNextContactDate?: Ordering | null;
};
export type salesRecord_salesRecordsQuery$variables = {
  filters?: MATSalesRecordFilter | null;
  order?: MATSalesRecordOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type salesRecord_salesRecordsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MATSalesRecordWindowPaginator_query">;
};
export type salesRecord_salesRecordsQuery = {
  response: salesRecord_salesRecordsQuery$data;
  variables: salesRecord_salesRecordsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "salesRecord_salesRecordsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MATSalesRecordWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "salesRecord_salesRecordsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATSalesRecordConnection",
        "kind": "LinkedField",
        "name": "salesRecords",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MATSalesRecordEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MATSalesRecord",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATOrganization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATConsultationHistory",
                    "kind": "LinkedField",
                    "name": "consultationHistories",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nextContactDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationStudentSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "region",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATTcmsUser",
                    "kind": "LinkedField",
                    "name": "assignedTo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATAcquisitionChannel",
                    "kind": "LinkedField",
                    "name": "latestAcquisitionChannel",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utmSource",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20822805ca74ea9e824db97d4281671c",
    "id": null,
    "metadata": {},
    "name": "salesRecord_salesRecordsQuery",
    "operationKind": "query",
    "text": "query salesRecord_salesRecordsQuery(\n  $filters: MATSalesRecordFilter\n  $order: MATSalesRecordOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...MATSalesRecordWindowPaginator_query_36liK3\n}\n\nfragment MATSalesRecordConnectionDataTable_matSalesRecordConnection on MATSalesRecordConnection {\n  edges {\n    node {\n      id\n      ...MATSalesRecordStatusLabel_matSalesRecord\n      ...MATSalesRecordStatusUpdateActionList_matSalesRecord\n      priority\n      ...MATSalesRecordPriorityUpdateActionList_matSalesRecord\n      title\n      organization {\n        id\n      }\n      name\n      phoneNumber\n      consultationHistories {\n        id\n        description\n        created\n        nextContactDate\n      }\n      organizationStudentSize\n      region\n      assignedTo {\n        id\n        name\n      }\n      latestAcquisitionChannel {\n        id\n        utmSource\n        created\n      }\n      description\n      organizationType\n    }\n  }\n}\n\nfragment MATSalesRecordPriorityUpdateActionList_matSalesRecord on MATSalesRecord {\n  id\n  priority\n}\n\nfragment MATSalesRecordStatusLabel_matSalesRecord on MATSalesRecord {\n  status\n}\n\nfragment MATSalesRecordStatusUpdateActionList_matSalesRecord on MATSalesRecord {\n  id\n  status\n}\n\nfragment MATSalesRecordWindowPaginator_query_36liK3 on Query {\n  salesRecords(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...MATSalesRecordConnectionDataTable_matSalesRecordConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n"
  }
};
})();

(node as any).hash = "d25499e836ce7c95f4f904c018cdf52d";

export default node;
